import { compareDesc, parseISO } from "date-fns"

import type { WidgetSettingsGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function widgetSettingsGet({
	tx,
	workspaceId,
}: QueryContext<object>) {
	const values = await tx
		.scan({ prefix: `${workspaceId}/widgetSettings/` })
		.values()
		.toArray()
	return (values as unknown as WidgetSettingsGet).sort((a, b) =>
		compareDesc(parseISO(a.createdAtIso), parseISO(b.createdAtIso)),
	)
}
