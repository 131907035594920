import type { ReadonlyJSONValue } from "replicache"

import type { IssuesGet, IssueUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function issueUpdate({
	tx,
	workspaceId,
	args,
}: MutationContext<IssueUpdate>) {
	const key = `${workspaceId}/issues/${args.id}`

	const prev = (await tx.get(key)) as unknown as IssuesGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Issue not found to update")
	}

	const newIssue: IssuesGet[number] = {
		...prev,
		isVisible: args.isVisible === undefined ? prev.isVisible : args.isVisible,
	}

	return tx.set(key, newIssue as unknown as ReadonlyJSONValue)
}
