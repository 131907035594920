import type { ReadonlyJSONValue } from "replicache"

import type { SegmentCreate, SegmentsGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function segmentCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<SegmentCreate>) {
	const key = `${workspaceId}/segments/${args.id}`
	if (await tx.has(key)) {
		throw new Error("Segment already exists")
	}

	const data: SegmentsGet[number] = {
		id: args.id,
		createdAtIso: new Date().toISOString(),
		color: args.color,
		companyIds: [],
		contactIds: [],
		hubspotId: null,
		intercomId: null,
		name: args.name,
		type: args.type,
		workspaceId,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
