import type { ReadonlyJSONValue } from "replicache"

import type { WidgetLinksGet, WidgetLinksUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function widgetLinksUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<WidgetLinksUpdate>) {
	const key = `${workspaceId}/widgetLinks/${args.id}`

	const prev = (await tx.get(key)) as unknown as WidgetLinksGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Widget links not found to update.")
	}

	const newWidgetSettings: WidgetLinksGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newWidgetSettings as unknown as ReadonlyJSONValue)
}
