import { formatDistanceToNowStrict } from "date-fns"

export const formatRelativeTime = (
	date: Date | string,
	addSuffix = false,
	isSnooze = false,
): string => {
	const parsedDate = typeof date === "string" ? new Date(date) : date

	if (isNaN(parsedDate.getTime())) {
		throw new Error("Invalid date provided")
	}

	const distance = formatDistanceToNowStrict(parsedDate, {
		addSuffix,
	})

	if (/seconds?/.test(distance)) {
		return isSnooze ? distance.replace(/ seconds?/, "sec") : "just now"
	}

	return distance
		.replace(/ minutes?/, "min")
		.replace(/ hours?/, "h")
		.replace(/ days?/, "d")
		.replace(/ weeks?/, "w")
		.replace(/ months?/, "mo")
		.replace(/ years?/, "y")
}
