import type { ReadonlyJSONValue } from "replicache"
import * as R from "remeda"

import type {
	CompaniesGet,
	ContactsGet,
	ProjectsGet,
	TagsGet,
	ThreadsGet,
	ThreadUpdate,
} from "@productlane/api"

import type { MutationContext } from "../../types"
import { contactsGet } from "../contacts/get"
import { contactUpdate } from "../contacts/update"

export async function threadUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<ThreadUpdate>) {
	const key = `${workspaceId}/threads/${args.id}`
	let companyId = args.companyId
	let contactId = args.contactId

	const prev = (await tx.get(key)) as unknown as ThreadsGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Thread not found to update")
	}

	const newTags = args.tagIds ?? prev.tagIds
	const newProjects = args.linkedProjectIds ?? prev.linkedProjectIds

	if (args.tagIds) {
		const tags = (await tx
			.scan({
				prefix: `${workspaceId}/tags/`,
			})
			.values()
			.toArray()) as unknown as TagsGet
		const tagsToUpdate = tags.filter(
			(t) => args.tagIds?.includes(t.id) || prev.tagIds.includes(t.id),
		)
		for (const tag of tagsToUpdate) {
			if (tag.feedbackIds.includes(args.id) && !args.tagIds.includes(tag.id)) {
				// remove feedback
				const payload: TagsGet[number] = {
					...tag,
					feedbackIds: tag.feedbackIds.filter((fId) => fId !== args.id),
				}
				await tx.set(
					`${workspaceId}/tags/${tag.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			} else {
				// add feedback
				const payload: TagsGet[number] = {
					...tag,
					feedbackIds: R.uniq([...tag.feedbackIds, args.id]),
				}
				await tx.set(
					`${workspaceId}/tags/${tag.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			}
		}
	}

	if (args.linkedProjectIds) {
		const projects = (await tx
			.scan({
				prefix: `${workspaceId}/projects/`,
			})
			.values()
			.toArray()) as unknown as ProjectsGet
		const projectsToUpdate = projects.filter(
			(t) =>
				args.linkedProjectIds?.includes(t.id) ??
				prev.linkedProjectIds.includes(t.id),
		)
		for (const project of projectsToUpdate) {
			if (
				project.feedbackIds.includes(args.id) &&
				!args.linkedProjectIds.includes(project.id)
			) {
				// remove feedback
				const payload: ProjectsGet[number] = {
					...project,
					feedbackIds: project.feedbackIds.filter((fId) => fId !== args.id),
				}
				await tx.set(
					`${workspaceId}/projects/${project.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			} else {
				// add feedback
				const payload: ProjectsGet[number] = {
					...project,
					feedbackIds: R.uniq([...project.feedbackIds, args.id]),
				}
				await tx.set(
					`${workspaceId}/projects/${project.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			}
		}
	}

	if (companyId) {
		// add feedback to newly assigned company
		const company = (await tx.get(
			`${workspaceId}/companies/${companyId}`,
		)) as unknown as CompaniesGet[number]
		if (company) {
			const payload: CompaniesGet[number] = {
				...company,
				// if args.companyId is defined this means we add the feedback to the company, otherwise remove it
				feedbackIds: R.uniq([...company.feedbackIds, args.id]),
			}
			await tx.set(
				`${workspaceId}/companies/${company.id}`,
				payload as unknown as ReadonlyJSONValue,
			)
		}

		// remove feedback from prev. assigned company
		if (prev.companyId) {
			const prevCompany = (await tx.get(
				`${workspaceId}/companies/${prev.companyId}`,
			)) as unknown as CompaniesGet[number]
			if (prevCompany) {
				const prevPayload: CompaniesGet[number] = {
					...prevCompany,
					feedbackIds: prevCompany.feedbackIds.filter((fId) => fId !== args.id),
				}
				await tx.set(
					`${workspaceId}/companies/${prevCompany.id}`,
					prevPayload as unknown as ReadonlyJSONValue,
				)
			}
		}

		// check if contact is part of company
		if (prev.contactId && !contactId) {
			const contacts = await contactsGet({ tx, workspaceId, args: {} })
			const contact = contacts.find((c) => c.id === prev.contactId)
			if (contact && !contact?.companyId) {
				// assigned contact is not part of any company yet, assign to company
				await contactUpdate({
					tx,
					workspaceId,
					args: { id: contact.id, companyId: company.id },
				})
			} else if (contact?.companyId !== company.id) {
				// unassign contact from insight
				contactId = null
			}
		}
	} else if (companyId === null && prev.companyId) {
		// update prev company to not include the feedback anymore
		const prevCompany = (await tx.get(
			`${workspaceId}/companies/${prev.companyId}`,
		)) as unknown as CompaniesGet[number]
		if (prevCompany) {
			const prevPayload: CompaniesGet[number] = {
				...prevCompany,
				feedbackIds: prevCompany.feedbackIds.filter((fId) => fId !== args.id),
			}
			await tx.set(
				`${workspaceId}/companies/${prevCompany.id}`,
				prevPayload as unknown as ReadonlyJSONValue,
			)
		}
	}

	if (contactId) {
		// assign new and remove from prev contact
		const contact = (await tx.get(
			`${workspaceId}/contacts/${contactId}`,
		)) as unknown as ContactsGet[number]
		if (contact) {
			const payload: ContactsGet[number] = {
				...contact,
				feedbackIds: R.uniq([...contact.feedbackIds, args.id]),
			}
			await tx.set(
				`${workspaceId}/contacts/${contact.id}`,
				payload as unknown as ReadonlyJSONValue,
			)
		}
		if (prev.contactId) {
			const prevContact = (await tx.get(
				`${workspaceId}/contacts/${prev.contactId}`,
			)) as unknown as ContactsGet[number]
			if (prevContact) {
				const prevPayload: ContactsGet[number] = {
					...prevContact,
					feedbackIds: prevContact.feedbackIds.filter((fId) => fId !== args.id),
				}
				await tx.set(
					`${workspaceId}/contacts/${prevContact.id}`,
					prevPayload as unknown as ReadonlyJSONValue,
				)
			}
		}

		if (!companyId) {
			if (!contact.companyId && prev.companyId) {
				// contact doesnt have company yet, assign
				await contactUpdate({
					tx,
					workspaceId,
					args: { id: contact.id, companyId: prev.companyId },
				})
			} else if (contact.companyId) {
				// assign contacts company to feedback
				companyId = contact.companyId
				// add feedback to newly assigned company
				const company = (await tx.get(
					`${workspaceId}/companies/${companyId}`,
				)) as unknown as CompaniesGet[number]
				if (company) {
					const payload: CompaniesGet[number] = {
						...company,
						// if args.companyId is defined this means we add the feedback to the company, otherwise remove it
						feedbackIds: R.uniq([...company.feedbackIds, args.id]),
					}
					await tx.set(
						`${workspaceId}/companies/${company.id}`,
						payload as unknown as ReadonlyJSONValue,
					)
				}

				// remove feedback from prev. assigned company
				if (prev.companyId) {
					const prevCompany = (await tx.get(
						`${workspaceId}/companies/${prev.companyId}`,
					)) as unknown as CompaniesGet[number]
					if (prevCompany) {
						const prevPayload: CompaniesGet[number] = {
							...prevCompany,
							feedbackIds: prevCompany.feedbackIds.filter(
								(fId) => fId !== args.id,
							),
						}
						await tx.set(
							`${workspaceId}/companies/${prevCompany.id}`,
							prevPayload as unknown as ReadonlyJSONValue,
						)
					}
				}
			}
		}
	} else if (contactId === null && prev.contactId) {
		// update prev contact to not include the feedback anymore
		const prevContact = (await tx.get(
			`${workspaceId}/contacts/${prev.contactId}`,
		)) as unknown as ContactsGet[number]
		if (prevContact) {
			const prevPayload: ContactsGet[number] = {
				...prevContact,
				// if args.contactId is defined this means we add the feedback to the contact, otherwise remove it
				feedbackIds: prevContact.feedbackIds.filter((fId) => fId !== args.id),
			}
			await tx.set(
				`${workspaceId}/contacts/${prevContact.id}`,
				prevPayload as unknown as ReadonlyJSONValue,
			)
		}
	}

	const newInsight: ThreadsGet[number] = {
		...prev,
		...args,
		tagIds: R.uniq(newTags),
		linkedProjectIds: R.uniq(newProjects),
	}
	if (typeof contactId !== "undefined") {
		newInsight.contactId = contactId
	}
	if (typeof companyId !== "undefined") {
		newInsight.companyId = companyId
	}

	return tx.set(key, newInsight as unknown as ReadonlyJSONValue)
}
