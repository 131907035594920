import type { Attachment } from "@/screens/app/threads/attachment-context"

export enum ThumbnailMimeType {
	JPEG = "image/jpeg",
	JPG = "image/jpg",
	PNG = "image/png",
	PDF = "application/pdf",
	CSV = "text/csv",
	WEBP = "image/webp",
	GIF = "image/gif",
}

export interface ThumbnailProps {
	attachment: Attachment
}
