import type {
	SlackMessageCreate,
	SlackMessagesGet,
} from "@productlane/api/src/replicache/types/slack-message"

import type { MutationContext } from "../../types"

export async function slackMessageCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<SlackMessageCreate>) {
	const key = `${workspaceId}/slackMessages/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Slack message already exists")
	}

	const now = new Date()

	const data: SlackMessagesGet[number] = {
		id: args.id,
		workspaceId: workspaceId,
		createdAtIso: now.toISOString(),
		channelId: args.channelId,
		html: args.html,
		type: "OUTBOUND",
		threadId: args.threadId,
		slackId: "",
		slackLink: "",
		slackUserId: "",
		userId: args.userId,
		attachments: args.attachments,
	}

	return tx.set(key, data)
}
