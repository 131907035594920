import type { TagGroupDelete, TagsGet } from "@productlane/api"

import type { MutationContext } from "../../types"
import { tagDelete } from "../tags/delete"
import { threadGet } from "../threads/getId"
import { threadUpdate } from "../threads/update"

export async function tagGroupDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<TagGroupDelete>) {
	const tags = (await tx
		.scan({ prefix: `${workspaceId}/tags/` })
		.values()
		.toArray()) as unknown as TagsGet
	const filtered = tags.filter((t) => t.tagGroupId === args.id)
	await Promise.all(
		filtered.map(async (f) => {
			await Promise.all(
				f.feedbackIds.map(async (fId) => {
					const thread = await threadGet({
						tx,
						workspaceId,
						args: { id: fId },
					})
					if (!thread) {
						throw new Error("Thread not found")
					}
					return threadUpdate({
						tx,
						workspaceId,
						args: {
							id: fId,
							tagIds: thread.tagIds.filter((tagId) => tagId !== f.id),
						},
					})
				}),
			)
			return tagDelete({ tx, workspaceId, args: { id: f.id } })
		}),
	)

	return tx.del(`${workspaceId}/tagGroups/${args.id}`)
}
