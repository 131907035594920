import { parseISO } from "date-fns"
import * as R from "remeda"

import type { SidebarSectionGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function sidebarSectionsGet({
	tx,
	workspaceId,
}: QueryContext<object>) {
	const values = await tx
		.scan({ prefix: `${workspaceId}/sidebarSections/` })
		.values()
		.toArray()

	const typed = values as unknown as SidebarSectionGet
	return R.sortBy(typed, [(x) => parseISO(x.createdAtIso), "asc"])
}
