import { compareDesc, parseISO } from "date-fns"

import type { WorkspacesGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function getWorkspaces({ tx }: QueryContext<object>) {
	const values = await tx.scan({ prefix: `workspaces/` }).values().toArray()
	return (values as unknown as WorkspacesGet).sort((a, b) =>
		compareDesc(parseISO(a.createdAtIso), parseISO(b.createdAtIso)),
	)
}
