import type { ReadonlyJSONValue } from "replicache"

import type { ProjectsGet, ProjectUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function projectUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<ProjectUpdate>) {
	const key = `${workspaceId}/projects/${args.id}`

	const prev = (await tx.get(key)) as unknown as ProjectsGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Project not found to update")
	}

	const newProject: ProjectsGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newProject as unknown as ReadonlyJSONValue)
}
