import { getAttributes } from "@tiptap/core"
import { Plugin, PluginKey } from "@tiptap/pm/state"

import type { MarkType } from "@tiptap/pm/model"

interface ClickHandlerOptions {
	type: MarkType
}

export function clickHandler(options: ClickHandlerOptions): Plugin {
	return new Plugin({
		key: new PluginKey("handleClickLink"),
		props: {
			handleClick: (view, _pos, event) => {
				if (event.button !== 0) {
					return false
				}

				// This fix bug where when text are highlighted or bolded or italic link don't open.
				const eventTarget = event.target as HTMLElement
				if (eventTarget.nodeName !== "A" && !eventTarget.closest("a")) {
					return false
				}
				// End of the highlighted or bolded or italic link don't open bug.

				const attrs = getAttributes(view.state, options.type.name)
				const link = event.target as HTMLLinkElement

				const href = link?.href ?? attrs.href
				const target = link?.target ?? attrs.target

				if (link && href) {
					if (view.editable) {
						window.open(href, target)
					}

					return true
				}

				return false
			},
		},
	})
}
