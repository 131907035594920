import { useCallback, useMemo, useState } from "react"
import { parseISO } from "date-fns"
import * as R from "remeda"

import type {
	CompaniesGet,
	ContactsGet,
	TagGroupGet,
	TagsGet,
	ThreadsGet,
} from "@productlane/api"
import type { PainLevelStatistics } from "@productlane/api/src/browser"
import { getTagPainLevel } from "@productlane/api/src/browser"

import { useSortState } from "./global-state"
import { getFilteredThreads } from "./threads"

type TagResult = TagsGet[number] & {
	importance: {
		trend: number
		painLevel: number
		painLevelStatistics: PainLevelStatistics
	}
	segmentIds: string[]
}

export function useSortedTags({
	companies,
	contacts,
	segmentIds,
	tagGroups,
	tags,
	threads,
}: {
	companies: CompaniesGet
	contacts: ContactsGet
	segmentIds: string[]
	tagGroups: TagGroupGet
	tags: TagsGet
	threads: ThreadsGet
}) {
	const [maxMap, setMaxMap] = useState(new Map<string, number>())
	const { sortByImportance } = useSortState()

	const statusOrder = useMemo(
		() =>
			tagGroups.reduce(
				(acc, currentItem, index) => {
					acc[currentItem.id] = index + 1
					return acc
				},
				{} as Record<string, number>,
			),
		[tagGroups],
	)

	const sortTags = useCallback(
		(tags: Array<TagResult>) => {
			return R.sortBy(tags, [
				(tag) => statusOrder[tag.tagGroupId ?? ""] ?? 0,
				"asc",
			])
		},
		[statusOrder],
	)

	const sortedTags = useMemo(() => {
		const augmentedTags = []
		const filteredThreads = getFilteredThreads({
			companies,
			contacts,
			segmentIds,
			threads,
		})

		for (const tag of tags) {
			const tagThreads = filteredThreads.filter((i) =>
				tag.feedbackIds.includes(i.id),
			)
			const segmentIds: Array<string> = []
			for (const thread of tagThreads) {
				if (thread.contactId) {
					const contact = contacts.find((c) => c.id === thread.contactId)
					if (contact) {
						segmentIds.push(...contact.segmentIds)
					}
				}
				if (thread.companyId) {
					const company = companies.find((c) => c.id === thread.companyId)
					if (company) {
						segmentIds.push(...company.segmentIds)
					}
				}
			}
			augmentedTags.push({
				...tag,
				segmentIds,
				importance: {
					trend: 0,
					painLevel: 0,
					painLevelStatistics: {
						upvoteCount: null,
						feedbacks: {
							HIGH: 0,
							MEDIUM: 0,
							LOW: 0,
							UNKNOWN: 0,
						},
					},
				},
			})
		}

		const maxValues = new Map<string, number>(tagGroups.map((tg) => [tg.id, 0]))

		for (const tag of augmentedTags) {
			const max = maxValues.get(tag.tagGroupId) ?? 0
			// used to throw an error if max is undefined but that had some race conditiony stuff when an outcome was deleted but tags were still referencing it
			const pain = getTagPainLevel(tag, filteredThreads)

			if (pain.painLevel > max) {
				maxValues.set(tag.tagGroupId, pain.painLevel)
			}
			tag.importance.painLevel = pain.painLevel
			tag.importance.trend = pain.trend
			tag.importance.painLevelStatistics = {
				...pain.painLevelStatistics,
				upvoteCount: null,
			}
		}
		setMaxMap(maxValues)

		const sorted: Array<TagResult> = sortByImportance
			? R.sortBy(augmentedTags, [(x) => x.importance.painLevel, "desc"])
			: R.sortBy(augmentedTags, [(x) => parseISO(x.createdAtIso), "desc"])

		if (segmentIds.length > 0) {
			return sortTags(
				sorted.filter((p) =>
					segmentIds.some((sId) => p.segmentIds.includes(sId)),
				),
			)
		}
		return sortTags(sorted)
	}, [
		companies,
		contacts,
		threads,
		segmentIds,
		sortByImportance,
		sortTags,
		tagGroups,
		tags,
	])

	return { maxMap, sortedTags }
}
