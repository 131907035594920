import { createEnv } from "@t3-oss/env-core"
import { z } from "zod"

const isTestEnvironment = process.env.NODE_ENV === "test"

export const env = createEnv({
	/**
	 * The prefix that client-side variables must have. This is enforced both at
	 * a type-level and at runtime.
	 */
	clientPrefix: "VITE_",

	client: {
		VITE_POSTHOG_KEY: z.string().min(1),
		VITE_LIVE_URL: z.string().min(1),
		VITE_API_URL: z.string().min(1),
		VITE_BASE_URL: z.string().min(1),
		VITE_REPLICACHE_LICENSE_KEY: z.string().min(1),
		VITE_NOVU_APPLICATION_ID: z.string().min(1),
		VITE_ZOOM_OAUTH_CLIENT_ID: z.string().min(1),
		VITE_ZOOM_OAUTH_CALLBACK_URL: z.string().min(1),
		VITE_STRIPE_PUBLISHABLE_KEY: z.string().min(1),
		VITE_SENTRY_DSN: z.string().min(1),
		VITE_WIDGET_ORCHESTRATION_URL: z.string().min(1),
	},

	/**
	 * What object holds the environment variables at runtime. This is usually
	 * `process.env` or `import.meta.env`.
	 */
	runtimeEnv: import.meta.env,
	/**
	 * By default, this library will feed the environment variables directly to
	 * the Zod validator.
	 *
	 * This means that if you have an empty string for a value that is supposed
	 * to be a number (e.g. `PORT=` in a ".env" file), Zod will incorrectly flag
	 * it as a type mismatch violation. Additionally, if you have an empty string
	 * for a value that is supposed to be a string with a default value (e.g.
	 * `DOMAIN=` in an ".env" file), the default value will never be applied.
	 *
	 * In order to solve these issues, we recommend that all new projects
	 * explicitly specify this option as true.
	 */
	emptyStringAsUndefined: true,
	skipValidation: isTestEnvironment,
})
