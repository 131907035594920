import { useMemo } from "react"
import * as R from "remeda"

import { useSortedProjects } from "@/lib/projects"
import {
	useCompanies,
	useContacts,
	useIssues,
	useIssueUpvotes,
	useProjects,
	useThreads,
	useUpvotes,
} from "@/lib/replicache"
import { useSegmentsFilterState } from "@/screens/app/segments/segment-filter"
import getDynamicContent from "../util/get-dynamic-content"

export const useDynamicText = () => {
	const projects = useProjects()
	const threads = useThreads()
	const contacts = useContacts()
	const companies = useCompanies()
	const upvotes = useUpvotes()
	const issues = useIssues()
	const issueUpvotes = useIssueUpvotes()
	const { segmentIds } = useSegmentsFilterState()
	const { sortedProjects: filteredProjects } = useSortedProjects({
		companies,
		contacts,
		issues,
		issueUpvotes,
		projects,
		segmentIds,
		threads,
		upvotes,
	})
	const plannedProjects = useMemo(
		() => R.filter(filteredProjects, (i) => i.state === "planned"),
		[filteredProjects],
	)
	const backlogProjects = useMemo(
		() => R.filter(filteredProjects, (i) => i.state === "backlog"),
		[filteredProjects],
	)
	const dynamicTemplateContent = useMemo(
		() => getDynamicContent(plannedProjects, backlogProjects),
		[plannedProjects, backlogProjects],
	)
	return { dynamicText: dynamicTemplateContent }
}
