import { create } from "zustand"
import { persist } from "zustand/middleware"

interface SetupWorkspaceState {
	step: number
	isSettingUpWorkspace: boolean
	setStep: (step: number) => void
	endSetup: () => void
	startSetup: () => void
	resetSetup: () => void
}

export const useSetupWorkspace = create<SetupWorkspaceState>()(
	persist(
		(set) => ({
			step: 0,
			isSettingUpWorkspace: false,
			setStep: (step) => set({ step }),
			endSetup: () => set({ isSettingUpWorkspace: false }),
			startSetup: () => set({ isSettingUpWorkspace: true }),
			resetSetup: () => set({ step: 0, isSettingUpWorkspace: false }),
		}),
		{
			name: "setup-workspace-state",
		},
	),
)
