import type { ReadonlyJSONValue } from "replicache"

import type { TagsGet, TagUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function tagUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<TagUpdate>) {
	const key = `${workspaceId}/tags/${args.id}`

	const prev = (await tx.get(key)) as unknown as TagsGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Tag not found to update")
	}

	const newTag: TagsGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newTag as unknown as ReadonlyJSONValue)
}
