import { useSubscribe } from "replicache-react"

import type { ReplicacheMutations, WorkspacesGet } from "@productlane/api"

import { getProjects } from "../procedures"
import { useDataStoreActions } from "../store"

export function useProjectsSubscribe({
	replicache,
	workspace,
}: {
	replicache: ReplicacheMutations | null
	workspace: WorkspacesGet[number] | undefined
}) {
	const { setProjects } = useDataStoreActions()

	const projects = useSubscribe(
		replicache,
		async (tx) => {
			if (workspace) {
				const p = await getProjects({
					tx,
					workspaceId: workspace.id,
					args: {},
				})
				const filteredByTeam = p.filter(
					(project) =>
						!project.linearTeamIds.length ||
						project.linearTeamIds.some(
							(tId) => workspace?.selectedTeamIds.includes(tId),
						),
				)
				setProjects(filteredByTeam)
				return p
			}
			return null
		},
		{ default: null, dependencies: [workspace] },
	)

	return { data: projects }
}
