export const COMPANY_ROLES = [
	{
		label: "Founder or management",
		value: "founder-or-management",
	},
	{
		label: "Engineering Manager",
		value: "engineering-manager",
	},
	{
		label: "Product Manager",
		value: "product-manager",
	},
	{
		label: "User Researcher",
		value: "user-researcher",
	},
	{
		label: "Sales Manager",
		value: "sales-manager",
	},
	{
		label: "Designer",
		value: "designer",
	},
	{
		label: "Software Engineer",
		value: "software-engineer",
	},
	{
		label: "Salesperson",
		value: "salesperson",
	},
	{
		label: "Customer Success",
		value: "customer-success",
	},
	{
		label: "I run business, operations, or customer development",
		value: "i-run-business-operations-or-customer-development",
	},
	{
		label: "I run an agency",
		value: "i-run-an-agency",
	},
	{
		label: "Freelancer",
		value: "freelancer",
	},
	{
		label: "Other",
		value: "other",
	},
] as const

export const COMPANY_SIZES = [
	{
		label: "Just me",
		value: "just-me",
	},
	{
		label: "1-5",
		value: "1-5",
	},
	{
		label: "5-25",
		value: "5-25",
	},
	{
		label: "25-100",
		value: "25-100",
	},
	{
		label: "100-250",
		value: "100-250",
	},
	{
		label: "250-1000",
		value: "250-1000",
	},
	{
		label: "1000+",
		value: "1000+",
	},
	{
		label: "Prefer not to share",
		value: "prefer-not-to-share",
	},
] as const

export const REFERRAL_SOURCES = [
	{
		label: "Word of mouth",
		value: "word-of-mouth",
	},
	{
		label: "LinkedIn",
		value: "linkedin",
	},
	{
		label: "Twitter",
		value: "twitter",
	},
	{
		label: "Product Hunt",
		value: "product-hunt",
	},
	{
		label: "Google",
		value: "google",
	},
	{
		label: "Other",
		value: "other",
	},
] as const

export const SIGNUP_REASONS = [
	{
		label: "Public Roadmap",
		value: "public-roadmap",
	},
	{
		label: "Customer Support (Email & Slack)",
		value: "customer-support",
	},
	{
		label: "Feedback Hub",
		value: "feedback-hub",
	},
	{
		label: "Changelog",
		value: "changelog",
	},
	{
		label: "Call Recoder",
		value: "call-recorder",
	},
	{
		label: "Other",
		value: "other",
	},
] as const
