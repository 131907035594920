import * as R from "remeda"

import type { IssuesGet, ProjectsGet } from "@productlane/api"
import type { PainLevelStatistics } from "@productlane/api/src/browser"

export type AugmentedProject = ProjectsGet[number] & {
	importance: {
		painLevel: number
		trend: number
		painLevelStatistics: PainLevelStatistics
	}
}

export type AugmentedIssue = IssuesGet[number] & {
	importance: {
		painLevel: number
		trend: number
		painLevelStatistics: PainLevelStatistics
	}
}

export type ProjectOrIssue =
	| {
			type: "project"
			data: AugmentedProject
	  }
	| {
			type: "issue"
			data: AugmentedIssue
	  }

export type ProjectsOrIssues =
	| {
			type: "project"
			data: Array<AugmentedProject>
	  }
	| {
			type: "issue"
			data: Array<AugmentedIssue>
	  }

export function groupProjectsByLinearRoadmaps(
	projects: Array<AugmentedProject>,
	isEnabled: boolean,
) {
	if (!isEnabled) {
		return {
			projectsWithRoadmaps: [],
			projectsWithoutRoadmaps: projects,
		}
	}

	try {
		const roadmaps = R.uniqBy(
			projects
				.map((i) => i.roadmaps)
				.flat()
				.map((it) => ({
					id: it.id,
					name: it.name,
				})),
			(i) => i.id,
		)
		const projectsWithRoadmaps = roadmaps.map((roadmap) => ({
			...roadmap,
			projects: projects.filter((i) =>
				i.roadmaps.find((it) => it.id === roadmap.id),
			),
		}))

		const projectsWithoutRoadmaps = projects.filter(
			(i) => i.roadmaps.length === 0,
		)

		return {
			projectsWithRoadmaps,
			projectsWithoutRoadmaps,
		}
	} catch (error) {
		return {
			projectsWithRoadmaps: [],
			projectsWithoutRoadmaps: projects,
		}
	}
}
