import type { ReadonlyJSONValue } from "replicache"

import type { RecordingsGet, RecordingUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

type PreviousType = RecordingsGet[number] | null

export async function recordingUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<RecordingUpdate>) {
	const key = `${workspaceId}/recordings/${args.id}`

	const prev = (await tx.get(key)) as PreviousType

	if (!prev || typeof prev !== "object") {
		throw new Error("Recording not found to update")
	}

	const newRecording: RecordingsGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newRecording as ReadonlyJSONValue)
}
