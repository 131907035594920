import { Extension } from "@tiptap/core"

export const EnterAsBreakExtension = Extension.create({
	name: "EnterExtension",
	addKeyboardShortcuts: () => ({
		Enter: ({ editor }) => {
			editor.chain().focus().setHardBreak().run()
			return true
		},
	}),
})
