export function getRoadmapStatusText(status: string) {
	if (status === "triage") {
		return "Triage"
	}
	if (status === "backlog") {
		return "Backlog"
	}
	if (status === "planned") {
		return "Planned"
	}
	if (status === "started") {
		return "In progress"
	}
	return "Backlog"
}

export function getRoadmapIssuesText(status: string) {
	if (status === "triage") {
		return "Triage"
	}
	if (status === "backlog") {
		return "Backlog"
	}
	if (status === "started") {
		return "Planned"
	}
	if (status === "unstarted") {
		return "Backlog"
	}
	return "Backlog"
}
