import { z } from "zod"

export interface BrandLink {
	name: string
	url: string
}

export interface BrandImageFormat {
	src: string
	background: "transparent" | null
	format: "png" | "svg" | "jpeg"
	height: number
	width: number
	size: number
}

export interface BrandLogo {
	type: "icon" | "logo" | "symbol" | "other"
	theme: "dark" | "light" | null
	formats: BrandImageFormat[]
}

export interface BrandColor {
	hex: string
	type: "accent" | "dark" | "light" | "brand"
	brightness: number
}

export interface BrandFont {
	name: string
	type: "title" | "body"
	origin: "google" | "custom" | "system" | null
	originId: string | null
}

export interface BrandImage {
	formats: BrandImageFormat[]
}

export const BrandfetchDataSchema = z.object({
	id: z.string().nullish(),
	name: z.string().nullish(),
	domain: z.string(),
	logos: z.array(
		z.object({
			type: z.string(),
			theme: z.string().nullish(),
			formats: z.array(
				z.object({
					src: z.string(),
					background: z.string().nullish(),
				}),
			),
		}),
	),
	colors: z.array(
		z.object({
			hex: z.string(),
			type: z.string(),
		}),
	),
})

export type BrandfetchData = z.infer<typeof BrandfetchDataSchema>

// export interface BrandfetchData {
// 	name: string
// 	domain: string
// 	claimed: boolean
// 	description: string
// 	links: BrandLink[]
// 	logos: BrandLogo[]
// 	colors: BrandColor[]
// 	fonts: BrandFont[]
// 	images: BrandImage[]
// }
