import type { ThreadDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function threadsDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<ThreadDelete>) {
	return tx.del(`${workspaceId}/threads/${args.id}`)
}
