import { useMemo } from "react"

export const useEntityById = <T extends { id: string }>(
	entities: T[] | null | undefined,
	entityId: string,
): T | undefined =>
	useMemo(
		() => entities?.find((entity) => entity.id === entityId),
		[entities, entityId],
	)
