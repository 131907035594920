import { useMemo } from "react"
import { parseISO } from "date-fns"
import * as R from "remeda"

import {
	useContacts,
	useIssues,
	useIssueUpvotes,
	useThreads,
} from "@/lib/replicache"
import { useEntityById } from "./useEntityById"
import { useRouteId } from "./useRouteId"

export const useAugmentedIssues = () => {
	const id = useRouteId()
	const issues = useIssues()
	const threads = useThreads()
	const contacts = useContacts()
	const issueUpvotes = useIssueUpvotes()
	const issue = useEntityById(issues, id)

	const augmentedIssueNotes = useMemo(
		() =>
			issue
				? R.pipe(
						threads,
						R.filter((thread) => issue.feedbackIds.includes(thread.id)),
						R.sortBy([(thread) => parseISO(thread.createdAtIso), "desc"]),
					)
				: [],
		[issue, threads],
	)

	const augmentedIssueUpvotes = useMemo(
		() =>
			issue
				? R.pipe(
						issueUpvotes,
						R.filter((upvote) => upvote.issueId === issue.id),
						R.map((upvote) => ({
							...upvote,
							contact: contacts.find(
								(contact) => contact.id === upvote.contactId,
							),
						})),
					)
				: [],
		[issue, issueUpvotes, contacts],
	)

	return {
		augmentedIssueNotes,
		augmentedIssueUpvotes,
	}
}
