import * as cheerio from "cheerio"
import * as R from "remeda"

import {
	DYNAMIC_TEMPLATE_ID,
	DYNAMIC_TEMPLATE_LIST_ID,
	TODO_ITEM_JOIN_UPON,
	TODO_ITEM_TEMPLATE_PLACEHOLDER,
} from "@productlane/lib/src/note-templates/helpers/constants"
import { DYNAMIC_TEMPLATE } from "@productlane/lib/src/note-templates/templates/DYNAMIC_TEMPLATE"
import { TODO_ITEM_TEMPLATE } from "@productlane/lib/src/note-templates/templates/TODO_ITEM_TEMPLATE"

import type { ProjectResult } from "@/lib/projects"

export default (
	plannedProjects: ProjectResult[],
	backlogProjects: ProjectResult[],
	limitPerGroup = 3,
) => {
	const $ = cheerio.load(DYNAMIC_TEMPLATE)
	const renderedProjects = R.pipe(
		R.concat(
			R.take(plannedProjects, limitPerGroup),
			R.take(backlogProjects, limitPerGroup),
		),
		R.take(limitPerGroup * 2),
		R.map((p) =>
			TODO_ITEM_TEMPLATE.replace(TODO_ITEM_TEMPLATE_PLACEHOLDER, p.name),
		),
	)
	$(`#${DYNAMIC_TEMPLATE_LIST_ID}`).html(
		renderedProjects.join(TODO_ITEM_JOIN_UPON),
	)
	return $(`#${DYNAMIC_TEMPLATE_ID}`).html()
}
