export enum UserColor {
	Grey = "Grey",
	DarkGray = "DarkGray",
	Purple = "Purple",
	Teal = "Teal",
	Green = "Green",
	Yellow = "Yellow",
	Orange = "Orange",
	Pink = "Pink",
	Red = "Red",
}

// avoid tailwind purging
export const USER_COLOR_PALETTE: Record<
	UserColor,
	{ hex: string; ring: string }
> = {
	Grey: {
		hex: "#95999f",
		ring: "ring-[#95999f]",
	},
	DarkGray: {
		hex: "#6e7b8b",
		ring: "ring-[#6e7b8b]",
	},
	Purple: {
		hex: "#5e6ad2",
		ring: "ring-[#5e6ad2]",
	},
	Teal: {
		hex: "#00b5ed",
		ring: "ring-[#00b5ed]",
	},
	Green: {
		hex: "#00b55c",
		ring: "ring-[#00b55c]",
	},
	Yellow: {
		hex: "#e8b600",
		ring: "ring-[#e8b600]",
	},
	Orange: {
		hex: "#e57a00",
		ring: "ring-[#e57a00]",
	},
	Pink: {
		hex: "#f38e82",
		ring: "ring-[#f38e82]",
	},
	Red: {
		hex: "#fb424c",
		ring: "ring-[#fb424c]",
	},
} as const
