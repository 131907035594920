/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Mark,
	markInputRule,
	markPasteRule,
	mergeAttributes,
} from "@tiptap/core"

export interface HighlightOptions {
	multicolor: boolean
	HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		highlight: {
			/**
			 * Set a highlight mark
			 */
			setHighlight: (attributes?: { color: string; id: string }) => ReturnType
			/**
			 * Toggle a highlight mark
			 */
			toggleHighlight: (attributes?: {
				color: string
				id: string
			}) => ReturnType
			/**
			 * Unset a highlight mark
			 */
			unsetHighlight: () => ReturnType
		}
	}
}

export const inputRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))$/
export const pasteRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))/g

export const HighlightExtension = Mark.create<HighlightOptions>({
	name: "highlight",
	inclusive: false,

	addOptions() {
		return {
			multicolor: false,
			HTMLAttributes: {},
		}
	},

	addAttributes() {
		if (!this.options.multicolor) {
			return {}
		}

		return {
			color: {
				default: null,
				parseHTML: (element) =>
					element.getAttribute("data-color") || element.style.backgroundColor,
				renderHTML: (attributes) => {
					if (!attributes.color) {
						return {}
					}

					return {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						"data-color": attributes.color,
						style: `background-color: ${attributes.color}; color: inherit;`,
					}
				},
			},
			id: {
				default: null,
				parseHTML: (element) => element.getAttribute("data-id"),
				renderHTML: (attributes) => {
					if (!attributes.id) {
						return {}
					}

					return {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						"data-id": attributes.id,
					}
				},
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: "mark",
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return [
			"mark",
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
			0,
		]
	},

	addCommands() {
		return {
			setHighlight:
				(attributes) =>
				({ commands }) => {
					return commands.setMark(this.name, attributes)
				},
			toggleHighlight:
				(attributes) =>
				({ commands }) => {
					return commands.toggleMark(this.name, attributes)
				},
			unsetHighlight:
				() =>
				({ commands }) => {
					return commands.unsetMark(this.name)
				},
		}
	},

	addKeyboardShortcuts() {
		return {
			"Mod-Shift-h": () => this.editor.commands.toggleHighlight(),
		}
	},

	addInputRules() {
		return [
			markInputRule({
				find: inputRegex,
				type: this.type,
			}),
		]
	},

	addPasteRules() {
		return [
			markPasteRule({
				find: pasteRegex,
				type: this.type,
			}),
		]
	},
})
