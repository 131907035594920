import type { ReadonlyJSONValue } from "replicache"
import * as R from "remeda"

import type { IssueLinkUpdate, ThreadsGet } from "@productlane/api"

import type { MutationContext } from "@/lib/replicache"
import { threadGet } from "@/lib/replicache/procedures"

/*
 * Update an existing customer need as a relation between issue and thread
 */
export async function issueLinkUpdate({
	tx,
	workspaceId,
	args,
}: MutationContext<IssueLinkUpdate>) {
	const feedback = await threadGet({
		workspaceId,
		tx,
		args: { id: args.feedbackId },
	})
	if (!feedback) {
		throw new Error("Could not find feedback")
	}
	const newFeedback: ThreadsGet[number] = {
		...feedback,
		linkedImportantIssueIds:
			args.priority === 1
				? R.uniq([...feedback.linkedImportantIssueIds, args.issueId])
				: feedback.linkedImportantIssueIds.filter((id) => id !== args.issueId),
	}
	await tx.set(
		`${workspaceId}/threads/${feedback.id}`,
		newFeedback as unknown as ReadonlyJSONValue,
	)
}
