import { create } from "zustand"
import { persist } from "zustand/middleware"

interface SortState {
	sortByImportance: boolean
	setSortByImportance: (value: boolean) => void
	sortByNewest: boolean | null
	setSortByNewest: (value: boolean | null) => void
}

export const useSortState = create<SortState>()(
	persist(
		(set) => ({
			sortByImportance: true,
			setSortByImportance: (value) => {
				set({ sortByImportance: value })
			},
			sortByNewest: null,
			setSortByNewest: (value) => {
				set({ sortByNewest: value })
			},
		}),
		{ name: "sort-state" },
	),
)

export type InboxState =
	| "all"
	| "new"
	| "my"
	| "close-loop"
	| "snoozed"
	| "processed"

interface InsightState {
	inboxState: InboxState
	setInboxState: (value: InboxState) => void
	offset: number
	setOffset: (value: number) => void
}

export const useInsightState = create<InsightState>()(
	persist(
		(set) => ({
			inboxState: "all",
			setInboxState: (value) => {
				set({ inboxState: value })
			},
			offset: 0,
			setOffset: (value) => {
				set({ offset: value })
			},
		}),
		{ name: "insight-state" },
	),
)

type Page = "active" | "closed"
interface RoadmapState {
	lastPage: Page
	setInboxState: (value: Page) => void
}

export const useRoadmapState = create<RoadmapState>()(
	persist(
		(set) => ({
			lastPage: "active",
			setInboxState: (value) => {
				set({ lastPage: value })
			},
		}),
		{ name: "roadmap-state" },
	),
)
