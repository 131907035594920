import type { CompanyDelete } from "@productlane/api"

import type { MutationContext } from "../../types"
import { contactsGet } from "../contacts/get"
import { contactUpdate } from "../contacts/update"
import { threadsGet } from "../threads/get"
import { threadUpdate } from "../threads/update"

export async function companyDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<CompanyDelete>) {
	const [threads, contacts] = await Promise.all([
		threadsGet({ args: {}, workspaceId, tx }),
		contactsGet({ args: {}, workspaceId, tx }),
	])
	const insightsOfCompany = threads.filter((t) => t.contactId === args.id)
	for (const contactInsight of insightsOfCompany) {
		await threadUpdate({
			args: { id: contactInsight.id, companyId: null },
			workspaceId,
			tx,
		})
	}
	const contactsOfCompany = contacts.filter((c) => c.companyId === args.id)
	for (const contact of contactsOfCompany) {
		await contactUpdate({
			args: { id: contact.id, companyId: null },
			workspaceId,
			tx,
		})
	}
	return tx.del(`${workspaceId}/companies/${args.id}`)
}
