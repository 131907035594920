import { cubicBezier } from "framer-motion"

// Custom easings from the animations.dev course https://www.notion.so/productlane/Animations-908a29c221f14038b47e9d270a18ff85
// using quad as the default, ordered from light to strong
export const easeOut = cubicBezier(0.25, 0.46, 0.45, 0.94)
export const easeOutQuad = cubicBezier(0.25, 0.46, 0.45, 0.94)
export const easeOutCubic = cubicBezier(0.215, 0.61, 0.355, 1)
export const easeOutQuart = cubicBezier(0.165, 0.84, 0.44, 1)
export const easeOutQuint = cubicBezier(0.23, 1, 0.32, 1)
export const easeOutExpo = cubicBezier(0.19, 1, 0.22, 1)
export const easeOutCirc = cubicBezier(0.075, 0.82, 0.165, 1)

// using quad as the default, ordered from light to strong
export const easeInOut = cubicBezier(0.455, 0.03, 0.515, 0.955)
export const easeInOutQuad = cubicBezier(0.455, 0.03, 0.515, 0.955)
export const easeInOutCubic = cubicBezier(0.645, 0.045, 0.355, 1)
export const easeInOutQuart = cubicBezier(0.77, 0, 0.175, 1)
export const easeInOutQuint = cubicBezier(0.86, 0, 0.07, 1)
export const easeInOutExpo = cubicBezier(1, 0, 0, 1)
export const easeInOutCirc = cubicBezier(0.785, 0.135, 0.15, 0.86)
