import type { MutationV0, MutationV1 } from "replicache"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Replicache } from "replicache"

import type { ReplicacheMutations } from "@productlane/api"

import { env } from "@/env"
import { usePokeListener } from "../poke"
import { useSession } from "../session"
import { getReplicacheMutators } from "./mutations"
import { useReplicacheSync } from "./sync"

export function useInitReplicache() {
	const { session } = useSession()
	const [rep, setRep] = useState<ReplicacheMutations | null>(null)

	const [isPulling, setIsPulling] = useState(false)

	const { setSyncing } = useReplicacheSync()

	useEffect(() => {
		let debounceTimeout: ReturnType<typeof setTimeout> | null = null

		if (isPulling) {
			debounceTimeout = setTimeout(() => {
				setSyncing(true)
			}, 400)
		} else {
			setSyncing(false)
		}

		return () => {
			if (debounceTimeout) clearTimeout(debounceTimeout)
		}
	}, [isPulling, setSyncing])

	usePokeListener({ rep })

	const navigate = useNavigate()

	useEffect(() => {
		if (session?.userId) {
			const r = new Replicache({
				name: `${session.workspaceId}/${session.userId}`,
				licenseKey: env.VITE_REPLICACHE_LICENSE_KEY,
				pushURL: `${env.VITE_API_URL}/api/replicache/push`,
				pullURL: `${env.VITE_API_URL}/api/replicache/pull`,
				mutators: getReplicacheMutators(session.workspaceId),
				schemaVersion: "1e148532-482b-4ada-afed-52d6aa8f2a00",
				pusher: async (requestBody) => {
					const maxMutationsPerBatch = 5
					const totalBatches =
						Math.floor(requestBody.mutations.length / maxMutationsPerBatch) + 1

					const mutations: (MutationV0 | MutationV1)[] = [
						...requestBody.mutations,
					]

					const httpStatusCodes: number[] = []
					let batch = 0

					while (batch < totalBatches) {
						requestBody.mutations = [
							...mutations.slice(
								batch * maxMutationsPerBatch,
								Math.min((batch + 1) * maxMutationsPerBatch, mutations.length),
							),
						] as MutationV1[]

						const res = await fetch(`${env.VITE_API_URL}/api/replicache/push`, {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(requestBody),
							credentials: "include",
						})

						httpStatusCodes.push(res.status)

						batch++
					}

					return {
						httpRequestInfo: {
							errorMessage: "",
							httpStatusCode: httpStatusCodes.every((x) => x === 200)
								? 200
								: 400,
						},
					}
				},
				puller: async (requestBody) => {
					setIsPulling(true)

					const res = await fetch(`${env.VITE_API_URL}/api/replicache/pull`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(requestBody),
						credentials: "include",
					})
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const data = await res.json()

					setIsPulling(false)

					return {
						httpRequestInfo: {
							errorMessage: !res.ok ? res.statusText : "",
							httpStatusCode: res.status,
						},
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						response: data,
					}
				},
			})

			// This gets called when the push/pull API returns a `401`.
			r.getAuth = () => {
				navigate("/logout")
				return null
			}

			setRep(r)

			return () => {
				void r.close()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session?.userId, session?.workspaceId])

	return { rep }
}
