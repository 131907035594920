import type { ReadonlyJSONValue } from "replicache"

import type { ChangelogCreate, ChangelogsGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function changelogCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<ChangelogCreate>) {
	const key = `${workspaceId}/changelogs/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Changelog already exists")
	}

	const data: ChangelogsGet[number] = {
		id: args.id,
		createdAtIso: args.dateIso,
		archived: false,
		dateIso: args.dateIso,
		imageUrl: null,
		notes: null,
		projectId: null,
		published: false,
		title: "",
		workspaceId,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
