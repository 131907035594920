/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeAttributes, Node } from "@tiptap/core"

export interface HardBreakOptions {
	keepMarks: boolean
	HTMLAttributes: Record<string, any>
}

export const HardBreakExtension = Node.create<HardBreakOptions>({
	name: "hard_break",

	addOptions() {
		return {
			keepMarks: true,
			HTMLAttributes: {},
		}
	},

	inline: true,

	group: "inline",

	selectable: false,

	parseHTML() {
		return [{ tag: "br" }]
	},

	renderHTML({ HTMLAttributes }) {
		return ["br", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
	},

	renderText() {
		return "\n"
	},
})
