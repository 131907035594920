import type { CompaniesGet, ContactsGet, ThreadsGet } from "@productlane/api"

export function getFilteredThreads({
	companies,
	contacts,
	segmentIds,
	threads,
}: {
	companies: CompaniesGet
	contacts: ContactsGet
	segmentIds: string[]
	threads: ThreadsGet
}): ThreadsGet {
	if (segmentIds.length === 0) {
		return threads
	}
	const filteredThreads: ThreadsGet = []
	for (const thread of threads) {
		if (thread.contactId) {
			const contact = contacts.find((c) => c.id === thread.contactId)
			if (contact) {
				if (
					!filteredThreads.map(({ id }) => id).includes(thread.id) &&
					(segmentIds.length === 0 ||
						contact.segmentIds.some((id) => segmentIds.includes(id)))
				) {
					filteredThreads.push(thread)
				}
			}
		}
		if (thread.companyId) {
			const company = companies.find((c) => c.id === thread.companyId)
			if (company) {
				if (
					!filteredThreads.map(({ id }) => id).includes(thread.id) &&
					(segmentIds.length === 0 ||
						company.segmentIds.some((id) => segmentIds.includes(id)))
				) {
					filteredThreads.push(thread)
				}
			}
		}
	}

	return filteredThreads
}
