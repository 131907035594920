import type { ReadonlyJSONValue } from "replicache"

import type { SnippetsGet, SnippetUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function snippetUpdate({
	tx,
	workspaceId,
	args,
}: MutationContext<SnippetUpdate>) {
	const key = `${workspaceId}/snippets/${args.id}`

	const prev = (await tx.get(key)) as unknown as SnippetsGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Snippet not found to update")
	}

	const newTag: SnippetsGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newTag as unknown as ReadonlyJSONValue)
}
