import type { ReadonlyJSONValue } from "replicache"

import type { CustomerCreate, CustomerGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function customerCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<CustomerCreate>) {
	const key = `${workspaceId}/customers/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Customer already exists")
	}

	const now = new Date()

	const data: CustomerGet = {
		id: args.id,
		createdAtIso: now.toISOString(),
		isDeleted: false,
		name: args.name,
		size: null,
		revenue: null,
		tierId: null,
		tierName: null,
		statusId: null,
		statusName: null,
		statusColor: null,
		workspaceId,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
