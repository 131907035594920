import type { EmailCreate, SlackMessageCreate } from "@productlane/api"
import { generateId } from "@productlane/lib/src/browser"

import type { Attachment } from "../../../attachment-context"

export const fileToBase64 = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const fileReader = new FileReader()
		fileReader.readAsDataURL(file)
		fileReader.onload = () => {
			if (fileReader.result) {
				resolve(fileReader.result as string)
			} else {
				reject(new Error("Failed to convert file to base64."))
			}
		}
		fileReader.onerror = (error) => reject(error)
	})

export function convertToEmailAttachments(
	attachments: Array<Attachment>,
): EmailCreate["attachments"] {
	const mappedAttachments: EmailCreate["attachments"] = attachments.map(
		(a) => ({
			Name: a.name,
			Content: (a.url ?? "").replace(/^data:.*?;base64,/, ""),
			ContentType: a.type,
			ContentLength: a.size,
		}),
	)

	return mappedAttachments
}

export function convertToSlackAttachments(
	attachments: Array<Attachment>,
): SlackMessageCreate["attachments"] {
	const mappedAttachments: SlackMessageCreate["attachments"] = attachments.map(
		(a) => ({
			name: a.name,
			type: a.type,
			url: a.url ?? "",
			id: generateId(),
			size: a.size,
		}),
	)

	return mappedAttachments
}
