import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query"
import { httpLink, loggerLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"
import superjson from "superjson"

import type { AppRouter } from "@productlane/api"

import type { QueryClientConfig } from "@tanstack/react-query"
import type { TRPCClientErrorLike } from "@trpc/client"
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server"
import { env } from "@/env"

export const getBaseUrl = () => {
	return `http://localhost:3000`
}

//onError: (error) => {
// 	const typedError = error as TRPCClientErrorLike<AppRouter>
// 	toast.error({
// 		title: typedError.data?.code ?? "Error",
// 		description: typedError.message,
// 	})
// },

const queryClientConfig: QueryClientConfig = {
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
			retry: 0,
		},
	},
	mutationCache: new MutationCache({}),
	queryCache: new QueryCache({
		onError: (error) => {
			console.error("Error happened: ", error)
			const typedError = error as TRPCClientErrorLike<AppRouter>

			if (typedError.data?.code === "UNAUTHORIZED") {
				// If we get an UNAUTHORIZED error (this usually means the session has expired we redirect to /logout)
				window.location.href = "/logout"
				return
			}
		},
	}),
}

export const reactQueryClient = new QueryClient(queryClientConfig)

export const api = createTRPCReact<AppRouter>({})

export const apiClient = api.createClient({
	links: [
		loggerLink({
			enabled: (opts) =>
				import.meta.env.MODE === "development" ||
				(opts.direction === "down" && opts.result instanceof Error),
		}),
		httpLink({
			url: `${env.VITE_API_URL}/api/trpc`,
			fetch(url, options) {
				return fetch(url, {
					...options,
					credentials: "include",
				})
			},
		}),
	],
	transformer: superjson,
})

/**
 * Inference helper for inputs
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>
/**
 * Inference helper for outputs
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>
