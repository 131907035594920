import type { CustomerDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function customerDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<CustomerDelete>) {
	return tx.del(`${workspaceId}/customers/${args.id}`)
}
