import type { ReadonlyJSONValue } from "replicache"

import type { CommentsGet, CommentUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function commentUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<CommentUpdate>) {
	const key = `${workspaceId}/comments/${args.id}`

	const prev = (await tx.get(key)) as CommentsGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Comment not found to update")
	}

	const newComment: CommentsGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newComment as ReadonlyJSONValue)
}
