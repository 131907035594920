import type { ReadonlyJSONValue } from "replicache"

import type { TagGroupCreate, TagGroupGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function tagGroupCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<TagGroupCreate>) {
	const key = `${workspaceId}/tagGroups/${args.id}`

	if (await tx.has(key)) {
		throw new Error("TagGroup already exists")
	}

	const now = new Date()

	const data: TagGroupGet[number] = {
		id: args.id,
		createdAtIso: now.toISOString(),
		color: args.color,
		name: args.name,
		tagIds: [],
		workspaceId,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
