/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeAttributes, Node, wrappingInputRule } from "@tiptap/core"

import type { Node as ProseMirrorNode } from "@tiptap/pm/model"

export interface TaskItemOptions {
	onReadOnlyDone?: (node: ProseMirrorNode, done: boolean) => boolean
	nested: boolean
	HTMLAttributes: Record<string, any>
}

export const inputRegex = /^\s*(\[([( |x])?\])\s$/

export const CustomTaskItemExtension = Node.create<TaskItemOptions>({
	name: "todo_item",

	addOptions() {
		return {
			nested: true,
			HTMLAttributes: {
				class: "flex",
			},
		}
	},

	content() {
		return this.options.nested ? "paragraph block*" : "paragraph+"
	},

	defining: true,

	addAttributes() {
		return {
			done: {
				default: false,
				keepOnSplit: false,
				parseHTML: (element) => element.getAttribute("data-done") === "true",
				renderHTML: (attributes) => ({
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					"data-done": attributes.done,
				}),
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: `li[data-type="${this.name}"]`,
				priority: 51,
			},
		]
	},

	renderHTML({ node, HTMLAttributes }) {
		return [
			"li",
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
				"data-type": this.name,
			}),
			[
				"label",
				[
					"input",
					{
						type: "checkbox",
						checked: node.attrs.done ? "checked" : null,
					},
				],
				["span"],
			],
			["div", 0],
		]
	},

	addKeyboardShortcuts() {
		const shortcuts = {
			Enter: () => this.editor.commands.splitListItem(this.name),
			"Shift-Tab": () => this.editor.commands.liftListItem(this.name),
		}

		if (!this.options.nested) {
			return shortcuts
		}

		return {
			...shortcuts,
			Tab: () => this.editor.commands.sinkListItem(this.name),
		}
	},

	addNodeView() {
		return ({ node, HTMLAttributes, getPos, editor }) => {
			const listItem = document.createElement("li")
			const outermostWrapper = document.createElement("div")
			outermostWrapper.classList.add("flex", "not-prose", "items-center")
			const checkboxWrapper = document.createElement("label")
			const checkbox = document.createElement("input")
			const content = document.createElement("div")
			content.classList.add("ml-1")

			checkboxWrapper.contentEditable = "false"
			checkbox.type = "checkbox"
			checkbox.addEventListener("change", (event) => {
				// if the editor isn’t editable and we don't have a handler for
				// readonly checks we have to undo the latest change
				if (!editor.isEditable && !this.options.onReadOnlyDone) {
					checkbox.checked = !checkbox.checked

					return
				}

				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const { checked } = event.target as any

				if (editor.isEditable && typeof getPos === "function") {
					editor
						.chain()
						.focus(undefined, { scrollIntoView: false })
						.command(({ tr }) => {
							const position = getPos()
							const currentNode = tr.doc.nodeAt(position)

							tr.setNodeMarkup(position, undefined, {
								...currentNode?.attrs,
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								done: checked,
							})

							return true
						})
						.run()
				}
				if (!editor.isEditable && this.options.onReadOnlyDone) {
					// Reset state if onReadOnlyChecked returns false
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					if (!this.options.onReadOnlyDone(node, checked)) {
						checkbox.checked = !checkbox.checked
					}
				}
			})

			Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				listItem.setAttribute(key, value)
			})

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			listItem.dataset.checked = node.attrs.done
			if (node.attrs.done) {
				checkbox.setAttribute("checked", "checked")
			}

			outermostWrapper.append(checkboxWrapper, checkbox, content)
			listItem.append(outermostWrapper)

			Object.entries(HTMLAttributes).forEach(([key, value]) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				listItem.setAttribute(key, value)
			})

			return {
				dom: listItem,
				contentDOM: content,
				update: (updatedNode) => {
					if (updatedNode.type !== this.type) {
						return false
					}

					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					listItem.dataset.checked = updatedNode.attrs.done
					if (updatedNode.attrs.done) {
						checkbox.setAttribute("checked", "checked")
					} else {
						checkbox.removeAttribute("checked")
					}

					return true
				},
			}
		}
	},

	addInputRules() {
		return [
			wrappingInputRule({
				find: inputRegex,
				type: this.type,
				getAttributes: (match) => ({
					done: match[match.length - 1] === "x",
				}),
			}),
		]
	},
})
