import { useEffect } from "react"
import { create } from "zustand"
import { persist } from "zustand/middleware"

import type { SessionData } from "@productlane/auth"

import { api } from "./api"

interface AuthState {
	data: SessionData | null
	setSessionData: (value: SessionData | null) => void
}

export const useAuthState = create<AuthState>()(
	persist(
		(set) => ({
			data: null,
			setSessionData: (value) => {
				set({ data: value })
			},
		}),
		{ name: "auth-state" },
	),
)
const useAuthStateData = () => useAuthState((state) => state.data)

export function useSession(): {
	session: SessionData | null
	isLoading: boolean
	isLoaded: boolean
} {
	const localAuthData = useAuthStateData()
	const { setSessionData } = useAuthState()
	const userDataQuery = api.user.info.useQuery(undefined, {
		staleTime: Infinity,
		cacheTime: Infinity,
		refetchOnMount: true,
		refetchOnWindowFocus: true,
		refetchOnReconnect: true,
		useErrorBoundary: false,
	})

	useEffect(() => {
		// @ts-expect-error Global widget calls
		// eslint-disable-next-line
		window.Productlane.on("loaded", () => {
			if (userDataQuery.data?.email) {
				// @ts-expect-error Global widget calls
				// eslint-disable-next-line
				window.Productlane.set({
					user: {
						email: userDataQuery.data.email,
					},
				})
				// @ts-expect-error Globald widget calls
				// eslint-disable-next-line
				window.Productlane.off("loaded")
			}
		})
	}, [userDataQuery.data?.email])

	useEffect(() => {
		if (userDataQuery.data) {
			setSessionData(userDataQuery.data)
			// these classes are for google analytics conversion tracking
			document.body.classList.add("productlane-user")
			window.dataLayer = window.dataLayer || []
			// Find an existing entry with user_id in the data layer
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const existingEntry = window.dataLayer.find(
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				(entry) => entry.user_id !== undefined,
			)
			const userId = userDataQuery.data.userId
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (existingEntry && existingEntry?.user_id !== userId) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				existingEntry.user_id = userId
			} else if (!existingEntry) {
				window.dataLayer.push({
					user_id: userId,
				})
			}

			if (userDataQuery.data.workspaceId) {
				document.body.classList.add("productlane-workspace")
			}
		} else if (userDataQuery.data === null) {
			setSessionData(null)
		}
	}, [setSessionData, userDataQuery.data])
	return localAuthData
		? {
				session: localAuthData,
				isLoading: userDataQuery.isLoading,
				isLoaded: userDataQuery.isSuccess,
			}
		: {
				session: null,
				isLoading: userDataQuery.isLoading,
				isLoaded: userDataQuery.isSuccess,
			}
}
