import type { TagDelete } from "@productlane/api"

import type { MutationContext } from "../../types"
import { threadsGet } from "../threads/get"
import { threadGet } from "../threads/getId"
import { threadUpdate } from "../threads/update"

export async function tagDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<TagDelete>) {
	const threads = await threadsGet({ args: {}, workspaceId, tx })
	const threadsWithTag = threads.filter((i) => i.tagIds.includes(args.id))
	for (const tagThread of threadsWithTag) {
		const thread = await threadGet({
			tx,
			workspaceId,
			args: { id: tagThread.id },
		})
		if (!thread) {
			continue
		}
		await threadUpdate({
			args: {
				id: tagThread.id,
				tagIds: tagThread.tagIds.filter((t) => t !== args.id),
			},
			workspaceId,
			tx,
		})
	}
	return tx.del(`${workspaceId}/tags/${args.id}`)
}
