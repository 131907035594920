import { useCallback, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import type { ThreadContextState, ThreadTab } from "../thread-context"
import type { Virtualizer } from "@tanstack/react-virtual"
import { useInsightState } from "@/lib/global-state"
import { useCompanies, useThreads, useWorkspace } from "@/lib/replicache"
import { useFilteredNotes } from "../../notes/filter/use-filtered-notes"

export const useInitThread = (): ThreadContextState => {
	const navigate = useNavigate()
	const params = useParams()
	const id = params.id
	const tab = params.tab as ThreadTab
	const threads = useThreads()
	const workspace = useWorkspace()
	const thread = useMemo(() => {
		return threads.find((t) => t.id === id)
	}, [id, threads])

	const companies = useCompanies()
	const company = useMemo(
		() => companies.find(({ id }) => id === thread?.companyId),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[thread?.companyId, companies.length],
	)

	const { inboxState: insightState } = useInsightState()

	const filteredThreads = useFilteredNotes(insightState ?? "all")
	const nextThread = useMemo(() => {
		const currIdx = filteredThreads.findIndex((t) => t.id === thread?.id)
		const next = filteredThreads[currIdx + 1] ?? null
		return next
	}, [thread?.id, filteredThreads])
	const prevThread = useMemo(() => {
		const currIdx = filteredThreads.findIndex((t) => t.id === thread?.id)
		const next = filteredThreads[currIdx - 1] ?? null
		return next
	}, [thread?.id, filteredThreads])
	const goNext = useCallback(() => {
		if (!nextThread || !thread) {
			return
		}
		const currentPath = window.location.pathname
		const newPath = currentPath.replace(thread.id, nextThread.id)
		navigate(newPath, { replace: true })
	}, [navigate, nextThread, thread])
	const goPrev = useCallback(() => {
		if (!prevThread || !thread) {
			return
		}
		const currentPath = window.location.pathname
		const newPath = currentPath.replace(thread.id, prevThread.id)
		navigate(newPath, { replace: true })
	}, [navigate, prevThread, thread])
	const [isReply, _setIsReply] = useState(false)
	const [isComment, _setIsComment] = useState(false)
	const setIsReply = useCallback(
		(isReply: boolean) => {
			if (
				!workspace?.emailSettings?.isEnabled &&
				!workspace?.slackConnectSettings?.length
			) {
				return
			}
			_setIsReply(isReply)
			if (isReply) {
				_setIsComment(false)
			}
		},
		[
			workspace?.emailSettings?.isEnabled,
			workspace?.slackConnectSettings?.length,
		],
	)
	const setIsComment = useCallback((isComment: boolean) => {
		_setIsComment(isComment)
		if (isComment) {
			_setIsReply(false)
		}
	}, [])
	const [transcriptVirtualizer, setTranscriptVirtualizer] = useState<{
		v: Virtualizer<HTMLDivElement, Element>
	} | null>(null)
	const [transcriptAccordionValue, setTranscriptAccordionValue] = useState<
		string | undefined
	>(undefined)
	return {
		// @ts-expect-error Thread can be undefined, needs to be fixed
		thread,
		company,
		nextThread,
		prevThread,
		goNext,
		goPrev,
		tab,
		isReply,
		setIsReply,
		isComment,
		setIsComment,
		transcriptVirtualizer,
		setTranscriptVirtualizer,
		transcriptAccordionValue,
		setTranscriptAccordionValue,
	}
}
