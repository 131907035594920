import type { RecordingDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function recordingDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<RecordingDelete>) {
	return tx.del(`${workspaceId}/recordings/${args.id}`)
}
