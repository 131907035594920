import type { SegmentDelete } from "@productlane/api"

import type { MutationContext } from "../../types"
import { companiesGet } from "../companies/get"
import { companyUpdate } from "../companies/update"
import { contactsGet } from "../contacts/get"
import { contactUpdate } from "../contacts/update"
import { getSegments } from "./get"

export async function segmentDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<SegmentDelete>) {
	const segments = await getSegments({ tx, workspaceId, args: {} })
	const segment = segments.find((s) => s.id === args.id)
	if (!segment) {
		throw new Error("Segment not found")
	}
	if (segment.type === "COMPANY") {
		const companies = await companiesGet({ tx, workspaceId, args: {} })
		const filteredCompanies = companies.filter((c) =>
			c.segmentIds.includes(args.id),
		)
		for (const company of filteredCompanies) {
			await companyUpdate({
				tx,
				workspaceId,
				args: {
					id: company.id,
					segmentIds: company.segmentIds.filter((s) => s !== args.id),
				},
			})
		}
	} else if (segment.type === "CONTACT") {
		const contacts = await contactsGet({ tx, workspaceId, args: {} })
		const filteredContacts = contacts.filter((c) =>
			c.segmentIds.includes(args.id),
		)
		for (const company of filteredContacts) {
			await contactUpdate({
				tx,
				workspaceId,
				args: {
					id: company.id,
					segmentIds: company.segmentIds.filter((s) => s !== args.id),
				},
			})
		}
	}

	return tx.del(`${workspaceId}/segments/${args.id}`)
}
