import type { ReadonlyJSONValue } from "replicache"

import type { SidebarSectionGet, SidebarSectionUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function sidebarSectionUpdate({
	tx,
	workspaceId,
	args,
}: MutationContext<SidebarSectionUpdate>) {
	const key = `${workspaceId}/sidebarSections/${args.id}`

	const prev = (await tx.get(key)) as unknown as
		| SidebarSectionGet[number]
		| null

	if (!prev || typeof prev !== "object") {
		throw new Error("Sidebar section not found to update")
	}

	const newSidebarSection: SidebarSectionGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newSidebarSection as unknown as ReadonlyJSONValue)
}
