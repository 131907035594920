import type { ReadonlyJSONValue } from "replicache"

import type { CompanyCreate, CompanyGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function companyCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<CompanyCreate>) {
	const key = `${workspaceId}/companies/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Company already exists")
	}

	const now = new Date()

	const data: CompanyGet = {
		id: args.id,
		createdAtIso: now.toISOString(),
		isDeleted: false,
		autoAdd: args.autoAdd ?? null,
		brandfetchData: null,
		contactIds: [],
		domain: args.domain,
		feedbackIds: [],
		hubspotId: null,
		intercomId: null,
		name: args.name,
		productboardId: null,
		segmentIds: [],
		workspaceId,
		customerId: null,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
