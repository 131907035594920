import type { StateCreator } from "zustand"
import { create } from "zustand"
import { persist } from "zustand/middleware"

export type ContactsSortType =
	| "name-asc"
	| "name-desc"
	| "newest"
	| "oldest"
	| "notes-asc"
	| "notes-desc"

interface ContactsSortState {
	sortType: ContactsSortType
	setSortType: (type: ContactsSortType) => void
}

const filterDefinition: StateCreator<ContactsSortState> = (set) => ({
	sortType: "notes-desc",
	setSortType: (type) => {
		set({ sortType: type })
	},
})

export const useContactsSortState = create<ContactsSortState>()(
	persist(filterDefinition, {
		name: "contacts-sort-state",
	}),
)
