import { useMemo } from "react"
import * as R from "remeda"

import { useCompanies } from "@/lib/replicache"
import { useSegmentsFilterCompanyState } from "../../segments/segment-filter"
import { useCompaniesSortState } from "./companies-sort-state"

export function useSortedCompanies() {
	const companies = useCompanies()
	const { sortType } = useCompaniesSortState()
	const { segmentIds } = useSegmentsFilterCompanyState()

	const sortedCompanies = useMemo(() => {
		const filteredBySegments = companies.filter(
			(company) =>
				segmentIds.length === 0 ||
				company.segmentIds.some((id) => segmentIds.includes(id)),
		)
		if (sortType === "notes-desc") {
			return R.sortBy(filteredBySegments, [(x) => x.feedbackIds.length, "desc"])
		}
		if (sortType === "notes-asc") {
			return R.sortBy(filteredBySegments, [(x) => x.feedbackIds.length, "asc"])
		}
		if (sortType === "newest") {
			return R.sortBy(filteredBySegments, [(x) => x.createdAtIso, "desc"])
		}
		if (sortType === "oldest") {
			return R.sortBy(filteredBySegments, [(x) => x.createdAtIso, "asc"])
		}
		if (sortType === "name-asc") {
			return R.sortBy(filteredBySegments, [(x) => x.name, "asc"])
		}
		if (sortType === "name-desc") {
			return R.sortBy(filteredBySegments, [(x) => x.name, "desc"])
		}
		return filteredBySegments
	}, [companies, sortType, segmentIds])

	return sortedCompanies
}
