import type { SidebarSectionDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function sidebarSectionDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<SidebarSectionDelete>) {
	return tx.del(`${workspaceId}/sidebarSections/${args.id}`)
}
