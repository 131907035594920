import type { ReadonlyJSONValue } from "replicache"

import type { SnippetCreate, SnippetsGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function snippetCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<SnippetCreate>) {
	const key = `${workspaceId}/snippets/${args.id}`
	if (await tx.has(key)) {
		throw new Error("Snippet already exists")
	}

	const now = new Date()

	const data: SnippetsGet[number] = {
		id: args.id,
		createdAtIso: now.toISOString(),
		workspaceId,
		title: args.title,
		html: args.html,
		...(args.emailId ? { emailId: args.emailId } : {}),
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
