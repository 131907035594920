import type { StateCreator } from "zustand"
import { create } from "zustand"
import { persist } from "zustand/middleware"

interface NotesMultiSelectState {
	noteIds: Array<string>
	toggleNoteId: (id: string) => void
	clearNoteIds: () => void
	bulkSelect: (ids: Array<string>) => void
}

const filterDefinition: StateCreator<NotesMultiSelectState> = (set, get) => ({
	noteIds: [],
	toggleNoteId: (id) => {
		const current = get().noteIds
		if (current.includes(id)) {
			// remove
			set({ noteIds: current.filter((c) => c !== id) })
		} else {
			// add
			set({ noteIds: [...current, id] })
		}
	},
	clearNoteIds: () => {
		set({ noteIds: [] })
	},
	bulkSelect: (ids) => {
		set({ noteIds: ids })
	},
})

export const useNotesMultiSelectState = create<NotesMultiSelectState>()(
	persist(filterDefinition, {
		name: "notes-multi-select",
	}),
)

export function useIsNotesMultiSelectActive() {
	const isNotesMultiSelectActive = useNotesMultiSelectState(
		(state) => state.noteIds.length > 0,
	)
	return { isNotesMultiSelectActive }
}
