import React from "react"
import { parseISO } from "date-fns"

import { useWorkspace } from "./replicache"

export function useTrial(): {
	hasTrialEnded: boolean
} {
	const workspace = useWorkspace()
	const hasTrialEnded = React.useMemo(() => {
		const year = 2024
		const month = 5 // June (months are zero-indexed in JavaScript)
		const day = 3
		const hour = 16 // German time (CEST) is UTC+2, so 16:00 German time is 14:00 UTC
		const minute = 0
		const second = 0

		const dateFromWhichToStartShowingPaywall = new Date(
			Date.UTC(year, month, day, hour - 2, minute, second),
		)

		if (
			workspace?.plan === "free" &&
			parseISO(workspace.createdAtIso) > dateFromWhichToStartShowingPaywall
		) {
			return true
		}

		return false
	}, [workspace])

	return { hasTrialEnded }
}
