import { createContext, useContext } from "react"

import type { ReplicacheMutations } from "@productlane/api"

export const ReplicacheContext = createContext<{
	replicache: ReplicacheMutations
} | null>(null)

export function useReplicache() {
	const replicacheContext = useContext(ReplicacheContext)

	if (!replicacheContext) {
		throw new Error(
			"useReplicache has to be used within <ReplicacheContext.Provider>",
		)
	}

	return replicacheContext
}
