import { compareDesc, parseISO } from "date-fns"

import type { ProjectsGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function getProjects({ tx, workspaceId }: QueryContext<object>) {
	const values = await tx
		.scan({ prefix: `${workspaceId}/projects/` })
		.values()
		.toArray()
	const data = (values as unknown as ProjectsGet).sort((a, b) =>
		compareDesc(parseISO(a.createdAtIso), parseISO(b.createdAtIso)),
	)
	return data
}
