import { useCurrentTemplate } from "./useCurrentTemplate"
import { useDynamicText } from "./useDynamicText"
import { useStandardText } from "./useStandardText"

export const useFullText = () => {
	const currentTemplate = useCurrentTemplate()
	const { standardText } = useStandardText()
	const { dynamicText } = useDynamicText()

	return {
		fullText:
			currentTemplate?.isStandardEnabled || currentTemplate?.isDynamicEnabled
				? `${currentTemplate?.isStandardEnabled ? standardText : ""}${
						currentTemplate?.isDynamicEnabled ? dynamicText : ""
					}`
				: "",
	}
}
