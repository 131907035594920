/* eslint-disable @typescript-eslint/no-explicit-any */
import { Mark, markInputRule, mergeAttributes } from "@tiptap/core"

export interface BoldOptions {
	HTMLAttributes: Record<string, any>
}

// Linear uses strong apparently, not bold as marks

export const starInputRegex = /(?:^|\s)((?:\*\*)((?:[^*]+))(?:\*\*))$/
export const starPasteRegex = /(?:^|\s)((?:\*\*)((?:[^*]+))(?:\*\*))/g
export const underscoreInputRegex = /(?:^|\s)((?:__)((?:[^__]+))(?:__))$/
export const underscorePasteRegex = /(?:^|\s)((?:__)((?:[^__]+))(?:__))/g

export const StrongExtension = Mark.create<BoldOptions>({
	name: "strong",

	addOptions() {
		return {
			HTMLAttributes: {},
		}
	},

	parseHTML() {
		return [
			{
				tag: "strong",
			},
			{
				tag: "b",
				getAttrs: (node) => node.style.fontWeight !== "normal" && null,
			},
			{
				style: "font-weight",
				getAttrs: (value) => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return [
			"strong",
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
			0,
		]
	},

	addInputRules() {
		return [
			markInputRule({
				find: starInputRegex,
				type: this.type,
			}),
			markInputRule({
				find: underscoreInputRegex,
				type: this.type,
			}),
		]
	},
})
