import type { FeedbackTemplate } from "@productlane/db/browser"

export type NoteTemplate = Omit<FeedbackTemplate, "createdAt" | "updatedAt">

export enum TemplateType {
	"standard" = "standard",
	"dynamic" = "dynamic",
}

export interface TemplateProps {
	title: string
	subtitle?: string
	content: string
	type: TemplateType
	template: NoteTemplate
}

export type TemplateHeaderProps = Omit<
	TemplateProps,
	"content" | "type" | "template"
> & {
	hasPreview?: boolean
	toggled: boolean
	onToggle: (value: boolean) => void
}
