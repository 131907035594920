import { parseISO } from "date-fns"
import * as R from "remeda"

import type { SlackMessagesGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function slackMessagesGet({
	tx,
	workspaceId,
}: QueryContext<object>) {
	const values = await tx
		.scan({ prefix: `${workspaceId}/slackMessages/` })
		.values()
		.toArray()
	const typed = values as unknown as SlackMessagesGet
	return R.sortBy(typed, [(x) => parseISO(x.createdAtIso), "asc"])
}
