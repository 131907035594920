import { useSubscribe } from "replicache-react"

import type { ReplicacheMutations, WorkspacesGet } from "@productlane/api"

import { commentsGet } from "../procedures"
import { useDataStoreActions } from "../store"

export function useCommentsSubscribe({
	replicache,
	workspace,
}: {
	replicache: ReplicacheMutations | null
	workspace: WorkspacesGet[number] | undefined
}) {
	const { setComments } = useDataStoreActions()

	useSubscribe(
		replicache,
		async (tx) => {
			if (workspace) {
				const c = await commentsGet({
					tx,
					workspaceId: workspace.id,
					args: {},
				})
				setComments(c)
				return c
			}
			return []
		},
		{ default: [], dependencies: [workspace] },
	)
}
