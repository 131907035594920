import type { ReadonlyJSONValue } from "replicache"

import type { EmailCreate, EmailsGet } from "@productlane/api"
import { convertHtmlToText } from "@productlane/lib/src/convert-html"

import type { MutationContext } from "../../types"

export async function emailCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<EmailCreate>) {
	const key = `${workspaceId}/emails/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Email already exists")
	}

	const now = new Date()

	const data: EmailsGet[number] = {
		id: args.id,
		createdAtIso: now.toISOString(),
		dateIso: now.toISOString(),
		htmlContent: args.content,
		subject: args.subject,
		textContent: convertHtmlToText(args.content),
		threadId: args.threadId,
		type: "OUTBOUND",
		userId: args.userId,
		workspaceId: workspaceId,

		from: args.from,
		to: args.to,
		cc: args.cc,
		bcc: args.bcc,
		attachments: args.attachments,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
