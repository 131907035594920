import { useSubscribe } from "replicache-react"

import type { ReplicacheMutations, WorkspacesGet } from "@productlane/api"

import { threadsGet } from "../procedures"
import { useDataStoreActions } from "../store"

export function useThreadsSubscribe({
	replicache,
	workspace,
}: {
	replicache: ReplicacheMutations | null
	workspace: WorkspacesGet[number] | undefined
}) {
	const { setThreads } = useDataStoreActions()

	useSubscribe(
		replicache,
		async (tx) => {
			if (workspace) {
				const i = await threadsGet({
					tx,
					workspaceId: workspace.id,
					args: {},
				})
				setThreads(i)
				return i
			}
			return []
		},
		{ default: [], dependencies: [workspace] },
	)
}
