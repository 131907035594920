import type { ReadonlyJSONValue } from "replicache"

import type { TagCreate, TagGroupGet, TagsGet } from "@productlane/api"

import type { MutationContext } from "../../types"
import { tagGroupGet } from "../tagGroups/get"

export async function tagCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<TagCreate>) {
	const key = `${workspaceId}/tags/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Tag already exists")
	}

	const now = new Date()

	const data: TagsGet[number] = {
		id: args.id,
		createdAtIso: now.toISOString(),
		color: args.color,
		feedbackIds: [],
		icon: args.icon,
		kluGuid: null,
		name: args.name,
		tagGroupId: args.tagGroupId,
		summary: [],
		summaryUpdatedAtIso: null,
		workspaceId,
	}
	const tagGroups = await tagGroupGet({ tx, workspaceId, args: {} })
	const tagGroup = tagGroups.find((oc) => oc.id === args.tagGroupId)
	if (!tagGroup) {
		throw new Error("TagGroup not found")
	}
	const payload: TagGroupGet[number] = {
		...tagGroup,
		tagIds: [...tagGroup.tagIds, data.id],
	}
	await tx.set(
		`${workspaceId}/tagGroups/${tagGroup.id}`,
		payload as unknown as ReadonlyJSONValue,
	)

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
