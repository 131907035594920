type SharedShortcutType =
	| "D"
	| "E"
	| "G"
	| "I"
	| "J"
	| "K"
	| "M"
	| "O"
	| "P"
	| "R"
	| "U"
	| "A"
	| "esc"
	| "S"
	| "L"
	| "C"
	| "T"
type ShortcutSymbol =
	| "ALT"
	| "BACKSPACE"
	| "CMD"
	| "CTRL"
	| "ENTER"
	| "SHIFT"
	| SharedShortcutType
type ShortcutValue = "⌥" | "⌘" | "⏎" | "⇧" | "⌫" | SharedShortcutType

export const ShortcutSymbols: Record<ShortcutSymbol, ShortcutValue> = {
	ALT: "⌥",
	CMD: "⌘",
	CTRL: "⌘",
	ENTER: "⏎",
	SHIFT: "⇧",
	BACKSPACE: "⌫",
	esc: "esc",
	A: "A",
	D: "D",
	E: "E",
	G: "G",
	I: "I",
	J: "J",
	K: "K",
	M: "M",
	O: "O",
	P: "P",
	R: "R",
	S: "S",
	U: "U",
	L: "L",
	C: "C",
	T: "T",
}
