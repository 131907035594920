import { parseISO } from "date-fns"
import * as R from "remeda"

import type { EmailsGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function emailsGet({ tx, workspaceId }: QueryContext<object>) {
	const values = await tx
		.scan({ prefix: `${workspaceId}/emails/` })
		.values()
		.toArray()
	const typed = values as unknown as EmailsGet
	return R.sortBy(typed, [(x) => parseISO(x.createdAtIso), "asc"])
}
