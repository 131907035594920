import { useMemo } from "react"

import { getTimelineEvents } from "@productlane/api/src/browser"

import { api } from "@/lib/api"
import {
	useComments,
	useContacts,
	useEmails,
	useIssues,
	useProjects,
	useSlackMessages,
	useSlackUsers,
	useThreadMessages,
} from "@/lib/replicache"
import { useThreadContext } from "../thread-context"

export function useTimelineEvents() {
	const { thread } = useThreadContext()
	const teamMembers = api.workspace.members.useQuery(undefined, {
		cacheTime: Infinity,
		staleTime: Infinity,
	})
	const emails = useEmails()
	const threadMessages = useThreadMessages()
	const slackMessages = useSlackMessages()
	const slackUsers = useSlackUsers()
	const comments = useComments()
	const contacts = useContacts()
	const issues = useIssues()
	const projects = useProjects()

	const events = useMemo(() => {
		const events = getTimelineEvents({
			thread,
			comments,
			contacts,
			emails,
			threadMessages,
			slackMessages,
			slackUsers,
			issues,
			projects,
			users: teamMembers.data?.map((m) => m.user) ?? [],
		})
		return events
	}, [
		thread,
		slackUsers,
		comments,
		contacts,
		emails,
		threadMessages,
		issues,
		projects,
		teamMembers.data,
		slackMessages,
	])

	return events
}
