import {
	addDays,
	addHours,
	addMinutes,
	addMonths,
	addWeeks,
	set,
} from "date-fns"

const numberWordsMap: Record<string, number> = {
	zero: 0,
	one: 1,
	two: 2,
	three: 3,
	four: 4,
	five: 5,
	six: 6,
	seven: 7,
	eight: 8,
	nine: 9,
	ten: 10,
	eleven: 11,
	twelve: 12,
	thirteen: 13,
	fourteen: 14,
	fifteen: 15,
	sixteen: 16,
	seventeen: 17,
	eighteen: 18,
	nineteen: 19,
	twenty: 20,
	"twenty-one": 21,
	"twenty one": 21,
	twentyone: 21,
	"twenty-two": 22,
	"twenty two": 22,
	twentytwo: 22,
	"twenty-three": 23,
	"twenty three": 23,
	twentythree: 23,
	"twenty-four": 24,
	"twenty four": 24,
	twentyfour: 24,
	"twenty-five": 25,
	"twenty five": 25,
	twentyfive: 25,
	"twenty-six": 26,
	"twenty six": 26,
	twentysix: 26,
	"twenty-seven": 27,
	"twenty seven": 27,
	twentyseven: 27,
	"twenty-eight": 28,
	"twenty eight": 28,
	twentyeight: 28,
	"twenty-nine": 29,
	"twenty nine": 29,
	twentynine: 29,
	thirty: 30,
	"thirty-one": 31,
	"thirty one": 31,
	thirtyone: 31,
	"thirty-two": 32,
	"thirty two": 32,
	thirtytwo: 32,
	"thirty-three": 33,
	"thirty three": 33,
	thirtythree: 33,
	"thirty-four": 34,
	"thirty four": 34,
	thirtyfour: 34,
	"thirty-five": 35,
	"thirty five": 35,
	thirtyfive: 35,
	"thirty-six": 36,
	"thirty six": 36,
	thirtysix: 36,
	"thirty-seven": 37,
	"thirty seven": 37,
	thirtyseven: 37,
	"thirty-eight": 38,
	"thirty eight": 38,
	thirtyeight: 38,
	"thirty-nine": 39,
	"thirty nine": 39,
	thirtynine: 39,
	forty: 40,
	"forty-one": 41,
	"forty one": 41,
	fortyone: 41,
	"forty-two": 42,
	"forty two": 42,
	fortytwo: 42,
	"forty-three": 43,
	"forty three": 43,
	fortythree: 43,
	"forty-four": 44,
	"forty four": 44,
	fortyfour: 44,
	"forty-five": 45,
	"forty five": 45,
	fortyfive: 45,
	"forty-six": 46,
	"forty six": 46,
	fortysix: 46,
	"forty-seven": 47,
	"forty seven": 47,
	fortyseven: 47,
	"forty-eight": 48,
	"forty eight": 48,
	fortyeight: 48,
	"forty-nine": 49,
	"forty nine": 49,
	fortynine: 49,
	fifty: 50,
	"fifty-one": 51,
	"fifty one": 51,
	fiftyone: 51,
	"fifty-two": 52,
	"fifty two": 52,
	fiftytwo: 52,
	"fifty-three": 53,
	"fifty three": 53,
	fiftythree: 53,
	"fifty-four": 54,
	"fifty four": 54,
	fiftyfour: 54,
	"fifty-five": 55,
	"fifty five": 55,
	fiftyfive: 55,
	"fifty-six": 56,
	"fifty six": 56,
	fiftysix: 56,
	"fifty-seven": 57,
	"fifty seven": 57,
	fiftyseven: 57,
	"fifty-eight": 58,
	"fifty eight": 58,
	fiftyeight: 58,
	"fifty-nine": 59,
	"fifty nine": 59,
	fiftynine: 59,
	sixty: 60,
}
const unitsMap: Record<string, (date: Date, amount: number) => Date> = {
	minute: (date, amount) => set(addMinutes(date, amount), {}),
	hour: (date, amount) => set(addHours(date, amount), {}),
	day: (date, amount) => set(addDays(date, amount), { hours: 9, minutes: 0 }),
	week: (date, amount) => set(addWeeks(date, amount), { hours: 9, minutes: 0 }),
	month: (date, amount) =>
		set(addMonths(date, amount), { hours: 9, minutes: 0 }),
}

export function getDateSuggestionsForInput(
	input: string,
): Array<{ label: string; value: Date }> {
	try {
		let modifiedInput = input.trim().toLowerCase()
		if (modifiedInput.startsWith("in")) {
			// remove "in" and trim again
			modifiedInput = modifiedInput.substring(2).trim()
		}
		const splits = modifiedInput.split(" ").filter(Boolean)
		if (splits.length === 0) {
			// suggest with "1"
			return [
				{
					label: "In 1 minute",
					value: unitsMap.minute!(new Date(), 1),
				},
				{
					label: "In 1 hour",
					value: unitsMap.hour!(new Date(), 1),
				},
				{
					label: "In 1 day",
					value: unitsMap.day!(new Date(), 1),
				},
				{
					label: "In 1 week",
					value: unitsMap.week!(new Date(), 1),
				},
				{
					label: "In 1 month",
					value: unitsMap.month!(new Date(), 1),
				},
			]
		}
		const numberString = splits[0]!
		// the next word we expect to be a number either as a word or digit
		const digit = isNaN(Number(numberString))
			? numberWordsMap[numberString]
			: Number(numberString)

		if (!digit) {
			// failed to parse, abort
			return []
		}

		const unitString = splits[1]
		if (unitString) {
			// deduct unit
			const unitStringWithoutS = unitString.endsWith("s")
				? unitString.slice(0, -1)
				: unitString
			const unitFunction = unitsMap[unitStringWithoutS]
			if (unitFunction) {
				return [
					{
						label: `In ${digit} ${unitStringWithoutS}${digit > 1 ? "s" : ""}`,
						value: unitFunction(new Date(), digit),
					},
				]
			} else {
				// if unitString is not complete, suggest possible matches
				const possibleMatches = Object.keys(unitsMap).filter((unit) =>
					unit.startsWith(unitStringWithoutS),
				)
				return possibleMatches.map((unit) => {
					return {
						label: `In ${digit} ${unit}${digit > 1 ? "s" : ""}`,
						value: unitsMap[unit]!(new Date(), digit),
					}
				})
			}
		} else {
			// suggest every unit for the digit
			return [
				{
					label: `In ${digit} minute${digit > 1 ? "s" : ""}`,
					value: unitsMap.minute!(new Date(), digit),
				},
				{
					label: `In ${digit} hour${digit > 1 ? "s" : ""}`,
					value: unitsMap.hour!(new Date(), digit),
				},
				{
					label: `In ${digit} day${digit > 1 ? "s" : ""}`,
					value: unitsMap.day!(new Date(), digit),
				},
				{
					label: `In ${digit} week${digit > 1 ? "s" : ""}`,
					value: unitsMap.week!(new Date(), digit),
				},
				{
					label: `In ${digit} month${digit > 1 ? "s" : ""}`,
					value: unitsMap.month!(new Date(), digit),
				},
			]
		}
	} catch (error) {
		console.log(error)
		return []
	}
}
