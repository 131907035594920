/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { Options } from "react-hotkeys-hook"
import type {
	HotkeyCallback,
	Keys,
	OptionsOrDependencyArray,
} from "react-hotkeys-hook/dist/types"
import { useHotkeys } from "react-hotkeys-hook"

/**
 * Get an object property using a string object notation
 * Example: const obj = {a: b: {c: "hi"}} => get(obj, "a.b.c") = "hi"
 * From here: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
 */
export const get = (obj: any, path: string, defaultValue = undefined) => {
	const travel = (regexp: RegExp) =>
		String.prototype.split
			.call(path, regexp)
			.filter(Boolean)
			.reduce(
				(res, key) => (res !== null && res !== undefined ? res[key] : res),
				obj,
			)
	const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
	return result === undefined || result === obj ? defaultValue : result
}

export function isRadixFocusGuardOpen() {
	if (typeof document !== "undefined") {
		const focusGuard = document.querySelector("[data-radix-focus-guard]")
		if (focusGuard) {
			return true
		}
	}
	return false
}

export function useShortcut(
	keys: Keys,
	callback: HotkeyCallback,
	options?: Options,
	dependencies?: OptionsOrDependencyArray,
) {
	return useHotkeys(
		keys,
		callback,
		{ ignoreEventWhen: () => isRadixFocusGuardOpen(), ...options },
		dependencies,
	)
}

async function sleep(ms: number) {
	return new Promise<void>((resolve) => setTimeout(resolve, ms))
}

export async function minDelay<T>(promise: Promise<T>, ms: number) {
	const [p] = await Promise.all([promise, sleep(ms)])

	return p
}
