import * as R from "remeda"

import type {
	CompaniesGet,
	ContactsGet,
	IssuesGet,
	IssueUpvotesGet,
	ProjectsGet,
	SegmentsGet,
	TagsGet,
	ThreadsGet,
	UpvotesGet,
} from "@productlane/api"

export function getSegmentAggregation({
	companies,
	contacts,
	entity,
	issueUpvotes,
	segments,
	threads,
	upvotes,
}: {
	companies: CompaniesGet
	contacts: ContactsGet
	entity: (TagsGet | IssuesGet | ProjectsGet)[number]
	issueUpvotes: IssueUpvotesGet
	segments: SegmentsGet
	threads: ThreadsGet
	upvotes: UpvotesGet
}) {
	const segmentIds: Array<string> = []
	const tagThreads = threads.filter((t) => entity.feedbackIds.includes(t.id))
	const uniqueContactIds = new Set()
	const uniqueCompanyIds = new Set()
	for (const thread of tagThreads) {
		if (thread.contactId && !uniqueContactIds.has(thread.contactId)) {
			const contact = contacts.find((c) => c.id === thread.contactId)
			if (contact) {
				segmentIds.push(...contact.segmentIds)
			}
			if (contact?.companyId && !uniqueCompanyIds.has(contact.companyId)) {
				const company = companies.find((c) => c.id === contact.companyId)
				if (company) {
					segmentIds.push(...company.segmentIds)
				}
				uniqueCompanyIds.add(contact.companyId)
			}
			uniqueContactIds.add(thread.contactId)
		}
		if (thread.companyId && !uniqueCompanyIds.has(thread.companyId)) {
			const company = companies.find((c) => c.id === thread.companyId)
			if (company) {
				segmentIds.push(...company.segmentIds)
			}
			uniqueCompanyIds.add(thread.companyId)
		}
	}
	const isProject = !!(entity as ProjectsGet[number])?.linearProjectId
	if (isProject) {
		const filteredUpvotes = upvotes.filter((u) => u.projectId === entity.id)
		for (const upvote of filteredUpvotes) {
			const contact = contacts.find((c) => c.id === upvote.contactId)
			if (contact && !uniqueContactIds.has(contact.id)) {
				segmentIds.push(...contact.segmentIds)
			}
			if (contact?.companyId && !uniqueCompanyIds.has(contact.companyId)) {
				const company = companies.find((c) => c.id === contact.companyId)
				if (company) {
					segmentIds.push(...company.segmentIds)
				}
				uniqueCompanyIds.add(contact.companyId)
			}
			uniqueContactIds.add(upvote.contactId)
		}
	}
	const isIssue = !!(entity as IssuesGet[number])?.linearTicketId
	if (isIssue) {
		const filteredIssueUpvotes = issueUpvotes.filter(
			(u) => u.issueId === entity.id,
		)
		for (const upvote of filteredIssueUpvotes) {
			const contact = contacts.find((c) => c.id === upvote.contactId)
			if (contact && !uniqueContactIds.has(contact.id)) {
				segmentIds.push(...contact.segmentIds)
			}
			if (contact?.companyId && !uniqueCompanyIds.has(contact.companyId)) {
				const company = companies.find((c) => c.id === contact.companyId)
				if (company) {
					segmentIds.push(...company.segmentIds)
				}
				uniqueCompanyIds.add(contact.companyId)
			}
			uniqueContactIds.add(upvote.contactId)
		}
	}
	const grouped = R.groupBy(segmentIds, (x) => x)
	const max = Math.max(...Object.values(grouped).map((g) => g.length))
	const result = R.pipe(
		segments,
		R.filter((s) => segmentIds.includes(s.id)),
		R.map((s) => {
			const count = R.countBy(segmentIds, (x) => x === s.id)
			return {
				...s,
				importance: count,
				max,
			}
		}),
		R.sortBy([(x) => x.importance, "desc"]),
	)
	return result
}
