import { useMemo } from "react"
import * as R from "remeda"

import { useContacts } from "@/lib/replicache"
import { useSegmentsFilterCompanyState } from "../../segments/segment-filter"
import { useContactsSortState } from "./contacts-sort-state"

export function useSortedContacts() {
	const contacts = useContacts()
	const { sortType } = useContactsSortState()
	const { segmentIds } = useSegmentsFilterCompanyState()

	const sortedContacts = useMemo(() => {
		const filteredBySemgent = contacts.filter(
			(contact) =>
				segmentIds.length === 0 ||
				contact.segmentIds.some((id) => segmentIds.includes(id)),
		)
		if (sortType === "notes-desc") {
			return R.sortBy(filteredBySemgent, [(x) => x.feedbackIds.length, "desc"])
		}
		if (sortType === "notes-asc") {
			return R.sortBy(filteredBySemgent, [(x) => x.feedbackIds.length, "asc"])
		}
		if (sortType === "newest") {
			return R.sortBy(filteredBySemgent, [(x) => x.createdAtIso, "desc"])
		}
		if (sortType === "oldest") {
			return R.sortBy(filteredBySemgent, [(x) => x.createdAtIso, "asc"])
		}
		if (sortType === "name-asc") {
			return R.sortBy(filteredBySemgent, [(x) => x.name ?? x.email, "asc"])
		}
		if (sortType === "name-desc") {
			return R.sortBy(filteredBySemgent, [(x) => x.name ?? x.email, "desc"])
		}
		return filteredBySemgent
	}, [contacts, sortType, segmentIds])

	return sortedContacts
}
