import type { ThreadsGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function threadGet({
	tx,
	workspaceId,
	args,
}: MutationContext<{ id: string }>) {
	const value = await tx.get(`${workspaceId}/threads/${args.id}`)
	return value as unknown as ThreadsGet[number] | undefined
}
