import type { ReadonlyJSONValue } from "replicache"
import startCase from "lodash/startCase"

import type { ContactCreate, ContactsGet } from "@productlane/api"
import { commonEmailProviders, domainRegex } from "@productlane/lib/src/browser"

import type { MutationContext } from "../../types"
import { companyCreate } from "../companies/create"
import { companiesGet } from "../companies/get"

export async function contactCreate({
	tx,
	workspaceId,
	args,
}: // new company id is just for the case where a company is automatically created to ensure replayability
MutationContext<ContactCreate>) {
	const key = `${workspaceId}/contacts/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Contact already exists")
	}

	const now = new Date()

	const data: ContactsGet[number] = {
		id: args.id,
		createdAtIso: now.toISOString(),
		isDeleted: false,
		companyId: args.companyId ?? null,
		email: args.email,
		feedbackIds: [],
		hubspotId: null,
		imageUrl: null,
		intercomId: null,
		name: args.name,
		productboardId: null,
		segmentIds: [],
		workspaceId,
	}

	if (!args.companyId) {
		// guess company
		const domain = args.email.match(domainRegex)?.[0]?.toLowerCase()
		console.log("domain", domain)

		// Auto-add should be disabled for domains like gmail.com, outlook.com, live.com, etc.
		const shouldAutoAdd = domain && !commonEmailProviders.includes(domain)
		console.log("should add", shouldAutoAdd)

		if (shouldAutoAdd) {
			const company = await ensureCompany({
				tx,
				workspaceId,
				args: { domain, newCompanyId: args.newCompanyId },
			})
			if (company) {
				data.companyId = company.id
			} else {
				data.companyId = args.newCompanyId
			}
		}
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}

async function ensureCompany({
	args,
	workspaceId,
	tx,
}: MutationContext<{ domain: string; newCompanyId: string }>) {
	const companies = await companiesGet({ tx, workspaceId, args: {} })
	const company = companies.find((c) => c.domain === args.domain)
	if (company) {
		return company
	}

	// If there is no company, and we're not dealing with a common domain like gmail.com, create a company along with the new contact
	if (!company) {
		console.info("contactCreate: Creating new company", {
			domain: args.domain,
		})

		await companyCreate({
			tx,
			workspaceId,
			args: {
				id: args.newCompanyId,
				domain: args.domain,
				name: startCase(args.domain.slice(0, args.domain.indexOf("."))),
				autoAdd: true,
			},
		})
	}
}
