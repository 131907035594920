import { compareDesc, parseISO } from "date-fns"

import type { ThreadsGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function threadsGet({ tx, workspaceId }: QueryContext<object>) {
	const values = await tx
		.scan({ prefix: `${workspaceId}/threads/` })
		.values()
		.toArray()

	return (values as unknown as ThreadsGet).sort((a, b) => {
		return compareDesc(
			parseISO(
				a.lastInboundMessageAtIso ??
					a.lastOutboundMessageAtIso ??
					a.createdAtIso,
			),
			parseISO(
				b.lastInboundMessageAtIso ??
					b.lastOutboundMessageAtIso ??
					b.createdAtIso,
			),
		)
	})
}
