import type { ReadonlyJSONValue } from "replicache"

import type { CommentCreate, CommentsGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function commentCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<CommentCreate>) {
	const key = `${workspaceId}/comments/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Comment already exists")
	}

	const now = new Date()

	const data: CommentsGet[number] = {
		id: args.id,
		createdAtIso: now.toISOString(),
		content: args.content,
		feedbackId: args.feedbackId,
		userId: args.userId,
		workspaceId: workspaceId,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
