import type { UtteranceDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function utteranceDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<UtteranceDelete>) {
	return tx.del(`${workspaceId}/utterances/${args.id}`)
}
