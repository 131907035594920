import type { ContactDelete } from "@productlane/api"

import type { MutationContext } from "../../types"
import { threadsGet } from "../threads/get"
import { threadUpdate } from "../threads/update"
import { upvotesGet } from "../upvotes/get"
import { issueUpvotesGet } from "../upvotes/get-issue-upvotes"

export async function contactDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<ContactDelete>) {
	const threads = await threadsGet({ args: {}, workspaceId, tx })
	const threadsOfContact = threads.filter((t) => t.contactId === args.id)
	for (const contactThread of threadsOfContact) {
		await threadUpdate({
			args: { id: contactThread.id, contactId: null },
			workspaceId,
			tx,
		})
	}
	const upvotes = await upvotesGet({ args: {}, tx, workspaceId })
	for (const upvote of upvotes) {
		if (upvote.contactId === args.id) {
			await tx.del(`${workspaceId}/upvotes/${upvote.id}`)
		}
	}
	const issueUpvotes = await issueUpvotesGet({ args: {}, tx, workspaceId })
	for (const issueUpvote of issueUpvotes) {
		if (issueUpvote.contactId === args.id) {
			await tx.del(`${workspaceId}/issueUpvotes/${issueUpvote.id}`)
		}
	}

	return tx.del(`${workspaceId}/contacts/${args.id}`)
}
