import type { ReadonlyJSONValue } from "replicache"
import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useSubscribe } from "replicache-react"

import type { ReplicacheMutations, WorkspacesGet } from "@productlane/api"

import { useSession } from "../../session"
import { useSetupWorkspace } from "../../setup-workspace"
import { getWorkspaces } from "../procedures"
import { useDataStoreActions } from "../store"

export function useWorkspaceSubscribe({
	replicache,
}: {
	replicache: ReplicacheMutations | null
}): {
	data: WorkspacesGet[number] | undefined
	isInitialized: ReadonlyJSONValue
} {
	const { startSetup, setStep } = useSetupWorkspace()
	const navigate = useNavigate()
	const { session } = useSession()

	const { setWorkspaces, setWorkspace } = useDataStoreActions()

	const isInitialized = useSubscribe(
		replicache,
		async (tx) => {
			if (session) {
				const result = await tx.get(`${session.workspaceId}/initialized`)
				return result
			}
		},
		{ default: false, dependencies: [session] },
	)

	const workspaces = useSubscribe(
		replicache,
		async (tx) => {
			const w = await getWorkspaces({
				tx: tx,
				args: {},
				// dummy placeholder
				workspaceId: "",
			})
			setWorkspaces(w)
			return w
		},
		{ default: null, dependencies: [] },
	)

	useEffect(() => {
		if (workspaces !== null && workspaces.length === 0 && isInitialized) {
			startSetup()
			setStep(0)
			navigate("/setup-workspace", { replace: true })
		}
	}, [isInitialized, navigate, setStep, startSetup, workspaces])

	const workspace = useMemo(() => {
		if (session) {
			const workspace = workspaces?.find((w) => w.id === session.workspaceId)
			if (workspace) {
				setWorkspace(workspace)
			}
			return workspace
		}
	}, [session, setWorkspace, workspaces])

	return { data: workspace, isInitialized }
}
