import type { CommentDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function commentDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<CommentDelete>) {
	return tx.del(`${workspaceId}/comments/${args.id}`)
}
