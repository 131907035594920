import parse from "node-html-parser"
import { pipe } from "remeda"
import { z } from "zod"

import type { Email } from "@productlane/db"
import { convertHtmlToText } from "@productlane/lib/src/convert-html"

export const EmailContactSchema = z.object({
	name: z.string(),
	email: z.string(),
	mailboxHash: z.string(),
})
export const EmailAttachmentSchema = z.object({
	Content: z.string(),
	ContentLength: z.string().or(z.number()).optional().nullable(),
	Name: z.string(),
	ContentType: z.string(),
	ContentID: z.string().optional(),
})

export type EmailContact = z.infer<typeof EmailContactSchema>
export type EmailAttachment = z.infer<typeof EmailAttachmentSchema>

export const splitEmailChunks = (
	emailHtmlContent: string,
): [string, string] => {
	if (!emailHtmlContent) return ["", ""]

	let hiddenContent = ""

	const parsed = parse(emailHtmlContent).removeWhitespace()

	const chunks: [string, string] = [parsed.toString(), ""]

	const gmailQuotedMessage = parsed.querySelectorAll("[class$='gmail_quote']")
	if (gmailQuotedMessage.length) {
		gmailQuotedMessage.forEach((message) => {
			hiddenContent += message.toString() ?? ""
			message.remove()
		})
	}

	const gmailSignaturePrefix = parsed.querySelectorAll(
		"[class$='gmail_signature_prefix']",
	)
	if (gmailSignaturePrefix.length) {
		gmailSignaturePrefix.forEach((message) => {
			hiddenContent += message.toString() ?? ""
			message.remove()
		})
	}

	const gmailSignature = parsed.querySelectorAll("[class$='gmail_signature']")
	if (gmailSignature.length) {
		gmailSignature.forEach((message) => {
			hiddenContent += message.toString() ?? ""
			message.remove()
		})
	}

	const signature = parsed.querySelectorAll("#Signature")
	if (signature.length) {
		signature.forEach((message) => {
			hiddenContent += message.toString() ?? ""
			message.remove()
		})
	}

	//outlook
	const forwardMessage = parsed.querySelector("#appendonsend")
	if (forwardMessage) {
		const parent = forwardMessage.parentNode
		if (parent) {
			let nextElement = forwardMessage.nextElementSibling
			while (nextElement) {
				if (nextElement.tagName === "DIV" || nextElement.tagName === "HR") {
					hiddenContent += nextElement.toString()
					const currentElement = nextElement
					nextElement = nextElement.nextElementSibling
					currentElement.remove()
				} else {
					nextElement = nextElement.nextElementSibling
				}
			}
		}
		hiddenContent += forwardMessage.toString()
		forwardMessage.remove()
	}

	const wordSection = parsed.querySelector("[class^='WordSection']")
	if (wordSection) {
		parsed
			.querySelectorAll("[class^='WordSection'] > div")
			.forEach((element) => {
				hiddenContent += element.toString()
				element.remove()
			})
	}

	const msOutlookMobileSignature = parsed.querySelector(
		".ms-outlook-mobile-signature",
	)
	if (msOutlookMobileSignature) {
		hiddenContent += msOutlookMobileSignature.toString()
		msOutlookMobileSignature.remove()
	}
	const msOutlookMobileSignatureId = parsed.querySelector(
		"#ms-outlook-mobile-signature",
	)
	if (msOutlookMobileSignatureId) {
		let nextElement = msOutlookMobileSignatureId.nextElementSibling

		hiddenContent += msOutlookMobileSignatureId.toString()
		msOutlookMobileSignatureId.remove()

		// we need to loop through all the elements below the outlook signature
		while (nextElement) {
			hiddenContent += nextElement.toString()
			const sibling = nextElement.nextElementSibling
			nextElement.remove()
			nextElement = sibling
		}
	}
	const mailEditor = parsed.querySelector(
		"#mail-editor-reference-message-container",
	)
	if (mailEditor) {
		hiddenContent += mailEditor.toString()
		mailEditor.remove()
	}

	const appleTransformWrapper = parsed.querySelector(
		".x-apple-transform-wrapper",
	)
	if (appleTransformWrapper) {
		hiddenContent += appleTransformWrapper.toString()
		appleTransformWrapper.remove()
	}

	const frontSignature = parsed.querySelector(
		"[class^='main-style-'] > .front-signature",
	)
	if (frontSignature) {
		hiddenContent += frontSignature.toString()
		frontSignature.remove()
	}

	const blockquotes = parsed.querySelectorAll("blockquote")
	blockquotes.forEach((element) => {
		hiddenContent += element.toString()
		element.remove()
	})

	const messageSignatureSection = parsed.querySelector(
		"[name='messageSignatureSection']",
	)
	if (messageSignatureSection) {
		hiddenContent += messageSignatureSection.toString()
		messageSignatureSection.remove()
	}

	const messageReplySection = parsed.querySelector(
		"[name='messageReplySection']",
	)
	if (messageReplySection) {
		hiddenContent += messageReplySection.toString()
		messageReplySection.remove()
	}

	//removing our signature
	const signatureStart = parsed.querySelector('p:contains("--")')
	if (signatureStart) {
		let nextElement = signatureStart.nextElementSibling

		hiddenContent += signatureStart.toString()
		signatureStart.remove()

		// we need to loop through all the elements below our signature
		while (nextElement) {
			hiddenContent += nextElement.toString()
			const sibling = nextElement.nextElementSibling
			nextElement.remove()
			nextElement = sibling
		}
	}

	const regExp = /<div><\/div>|<p><\/p>|<div><br><\/div>/g
	const regExpSuperhuman =
		/<br><\/div><\/div><div><div><img[^>]*><\/div><br><\/div><br><br><\/div><\/div>/g
	//im not proud about this, but we should somehow handle the empty things after cropping them
	const regExpLtrAndImages =
		/<div[^>]*\sdir="ltr"[^>]*>[\s\S]*?<\/div>|<img[^>]*\sdata-unique-identifier[^>]*>/g

	chunks[0] = parsed.toString().replace(regExpSuperhuman, "")
	chunks[0] = parsed.toString().replace(regExpLtrAndImages, "")
	chunks[0] = parsed.toString().replace(regExp, "")
	chunks[1] = hiddenContent.replace(regExp, "")
	return chunks
}

export const populateEmailAttachments =
	(attachments: EmailAttachment[]) => (htmlContent: Email["htmlContent"]) =>
		htmlContent.replace(/src="cid:([^"]+)"/g, (_, p1: string) => {
			// `_` is the whole matched string, e.g., 'src="cid:someid"'
			// `p1` is the first captured group, the 'someid' part after 'cid:'

			const attachment = attachments.find((a) => a.ContentID?.includes(p1))

			return attachment
				? `src="data:${attachment.ContentType};base64,${attachment.Content}"`
				: 'src=""'
		})

const EMAIL_CONTENT_CHARS_LIMIT = 1000

export const formatEmailHtmlContent = (emailContent: string) =>
	pipe(
		emailContent,
		(content) => content.replace(/<https?:\/\/\S+>/g, ""),
		(content) => content.replace(/https?:\/\/\S+/g, ""),
		(content) => splitEmailChunks(content)[0],
		convertHtmlToText,
		(content) =>
			content.length > EMAIL_CONTENT_CHARS_LIMIT
				? `${content.slice(0, EMAIL_CONTENT_CHARS_LIMIT)}...`
				: content,
	)
