import { useMedia, useUpdateEffect } from "react-use"

const COLOR_SCHEME_QUERY = "(prefers-color-scheme: dark)"

interface UseDarkModeOutput {
	isDarkMode: boolean
}

export function useDarkMode(): UseDarkModeOutput {
	const isDarkOS = useMedia(COLOR_SCHEME_QUERY)

	useUpdateEffect(() => {
		if (isDarkOS) {
			document.documentElement.classList.add("dark")
		} else {
			document.documentElement.classList.remove("dark")
		}
	}, [isDarkOS])

	return {
		isDarkMode: isDarkOS,
	}
}
