import type { SlackMessageUndo } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function slackMessageUndo({
	tx,
	workspaceId,
	args,
}: MutationContext<SlackMessageUndo>) {
	const key = `${workspaceId}/slackMessages/${args.id}`
	return tx.del(key)
}
