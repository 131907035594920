import resolveConfig from "tailwindcss/resolveConfig"

import type {
	INotificationCenterStyles,
	INovuPopoverTheme,
	INovuThemePopoverProvider,
} from "@novu/notification-center"
import type { CSSFunctionOrObject } from "@novu/notification-center/dist/types/components/types"
import tailwindConfig from "../../tailwind.config"

const {
	theme: { colors, fontSize },
} = resolveConfig(tailwindConfig)

const UNIVERSAL_STYLES: INotificationCenterStyles = {
	footer: {
		root: {
			display: "none",
		},
	},
	bellButton: {
		dot: {
			opacity: 0,
		},
	},
}

const UNIVERSAL_THEMES: INovuPopoverTheme = {
	loaderColor: colors.green[500],

	header: {
		badgeColor: colors.green[500],
		badgeTextColor: colors.white,
	},

	notificationItem: {
		unread: {
			notificationItemBeforeBrandColor: "transparent",
		},
	},
}

const globalListItemStyle: CSSFunctionOrObject = {
	boxShadow: "none",
	padding: "8px 16px",
	margin: "16px 0px",
	borderRadius: 0,
	"background-color": "rgba(0,0,0,0)",
	"p:nth-child(1)": {
		fontWeight: 500,
		fontSize: 14,
	},
	"p:nth-child(3)": {
		fontWeight: 400,
		fontSize: 12,
		fontStyle: "italic",
	},
	"::before": {
		width: "4px",
		borderRadius: "0px 10px 10px 0px",
	},
}

export const NOVU_STYLES: {
	dark: INotificationCenterStyles
	light: INotificationCenterStyles
} = {
	dark: {
		...UNIVERSAL_STYLES,
		header: {
			title: {
				color: colors.gray[200],
				fontSize: fontSize.base[0],
			},
			root: {
				borderBottom: `1px solid ${colors.gray[700]}`,
			},
		},
		layout: {
			root: {
				background: `${colors.gray[800]}`,
				backdropFilter:
					"blur(10px) saturate(180%) contrast(80%) brightness(50%)",
			},
		},
		notifications: {
			listItem: {
				layout: {
					padding: "0px ",
				},
				read: {
					...globalListItemStyle,
					border: "0",
					":hover": {
						background: colors.gray[700],
					},
				},
				unread: {
					...globalListItemStyle,
					boxShadow: "none",
					":hover": {
						background: colors.gray[700],
					},
				},
			},
		},
	},
	light: {
		...UNIVERSAL_STYLES,
		header: {
			title: {
				fontSize: fontSize.base[0],
				color: colors.gray[800],
			},
			root: {
				borderBottom: `1px solid ${colors.gray[200]}`,
			},
		},
		notifications: {
			listItem: {
				timestamp: colors.gray[800],
				read: {
					...globalListItemStyle,
					":hover": {
						background: colors.gray[50],
					},
				},
				unread: {
					...globalListItemStyle,
					":hover": {
						background: colors.gray[50],
					},
				},
			},
		},
	},
}

export const NOVU_THEMES: INovuThemePopoverProvider = {
	dark: {
		...UNIVERSAL_THEMES,
		header: {
			fontColor: colors.gray[100],
			markAllAsReadButtonColor: colors.green[500],
			badgeColor: colors.green[500],
		},
		notificationItem: {
			read: {
				fontColor: colors.gray[400],
				timeMarkFontColor: colors.gray[300],
			},
			unread: {
				notificationItemBeforeBrandColor: colors.green[500],
				timeMarkFontColor: colors.gray[300],
				fontColor: colors.gray[200],
			},
		},
		actionsMenu: {
			dotsButtonColor: colors.gray[200],
			hoverColor: colors.gray[700],
			fontColor: colors.gray[200],
			dropdownColor: colors.gray[800],
		},
	},
	light: {
		...UNIVERSAL_THEMES,
		header: {
			fontColor: colors.gray[800],
			markAllAsReadButtonColor: colors.green[500],
			badgeColor: colors.green[500],
		},
		popover: {
			arrowColor: colors.white,
		},
		actionsMenu: {
			dotsButtonColor: colors.gray[800],
			hoverColor: colors.gray[100],
			fontColor: colors.gray[800],
			dropdownColor: colors.white,
		},
		notificationItem: {
			read: {
				fontColor: colors.gray[500],
				timeMarkFontColor: colors.gray[700],
			},
			unread: {
				notificationItemBeforeBrandColor: colors.green[500],
				timeMarkFontColor: colors.gray[700],
				fontColor: colors.gray[800],
			},
		},
	},
}
