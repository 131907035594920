import * as R from "remeda"

import type { CSVDataType, CSVRowType } from "../types/"
import { useAugmentedIssues } from "./useAugmentedIssues"
import { useAugmentedProjects } from "./useAugmentedProjects"
import { useAugmentedTags } from "./useAugmentedTags"

interface RecipientFormats {
	clipboardFormat: string
	csvFormat: CSVDataType
}

export const useRecipients = (): RecipientFormats => {
	const { augmentedIssueNotes, augmentedIssueUpvotes } = useAugmentedIssues()
	const { augmentedProjectNotes, augmentedProjectUpvotes } =
		useAugmentedProjects()
	const { augmentedTagNotes } = useAugmentedTags()
	const recipientsFromIssueNote = R.compact(
		R.map(augmentedIssueNotes, (item) => item?.contactEmail),
	)
	const recipientsFromIssueUpvote = R.compact(
		R.map(augmentedIssueUpvotes, (item) => item.contact?.email),
	)
	const recipientsFromProjectNote = R.compact(
		R.map(augmentedProjectNotes, (item) => item?.contactEmail),
	)
	const recipientsFromProjectUpvote = R.compact(
		R.map(augmentedProjectUpvotes, (item) => item.contact?.email),
	)
	const recipientsFromTagNote = R.compact(
		R.map(augmentedTagNotes, (item) => item?.contactEmail),
	)
	const recipients = R.uniq([
		...recipientsFromIssueNote,
		...recipientsFromIssueUpvote,
		...recipientsFromProjectNote,
		...recipientsFromProjectUpvote,
		...recipientsFromTagNote,
	])

	return {
		clipboardFormat: R.join(recipients, ", "),
		csvFormat: [
			["email"],
			...R.map(recipients, (email): CSVRowType => [email]),
		],
	}
}
