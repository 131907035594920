import { useMemo } from "react"
import { parseISO } from "date-fns"
import * as R from "remeda"

import {
	useContacts,
	useProjects,
	useThreads,
	useUpvotes,
} from "@/lib/replicache"
import { useEntityById } from "./useEntityById"
import { useRouteId } from "./useRouteId"

export const useAugmentedProjects = () => {
	const id = useRouteId()
	const contacts = useContacts()
	const upvotes = useUpvotes()
	const threads = useThreads()
	const projects = useProjects()
	const project = useEntityById(projects, id)

	const augmentedProjectNotes = useMemo(
		() =>
			project
				? R.pipe(
						threads,
						R.filter((thread) => project.feedbackIds.includes(thread.id)),
						R.sortBy([(thread) => parseISO(thread.createdAtIso), "desc"]),
					)
				: [],
		[project, threads],
	)

	const augmentedProjectUpvotes = useMemo(
		() =>
			project
				? R.pipe(
						upvotes,
						R.filter((upvote) => upvote.projectId === project.id),
						R.map((upvote) => ({
							...upvote,
							contact: contacts.find(
								(contact) => contact.id === upvote.contactId,
							),
						})),
					)
				: [],
		[contacts, project, upvotes],
	)

	return {
		augmentedProjectNotes,
		augmentedProjectUpvotes,
	}
}
