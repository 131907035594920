import {
	DYNAMIC_TEMPLATE_ID,
	DYNAMIC_TEMPLATE_LIST_ID,
} from "../helpers/constants"

export const DYNAMIC_TEMPLATE = `<div id="${DYNAMIC_TEMPLATE_ID}">
	<h3>Trending features</h3>
	<p>Ask if some of these features would solve a meaningful problem.</p>
	<ul class="p-0" data-type="todo_list" id="${DYNAMIC_TEMPLATE_LIST_ID}">
		<li class="flex" data-done data-type="todo_item">
			<label><input type="checkbox" checked="checked" /><span></span></label>
			<div><p>AI Opportunity Detection</p></div>
		</li>
		<li class="flex" data-done data-type="todo_item">
			<label><input type="checkbox" checked="checked" /><span></span></label>
			<div><p>Multi-select notes and features</p></div>
		</li>
		<li class="flex" data-done data-type="todo_item">
			<label><input type="checkbox" checked="checked" /><span></span></label>
			<div><p>Multi-select notes and features</p></div>
		</li>
		<li class="flex" data-done data-type="todo_item">
			<label><input type="checkbox" checked="checked" /><span></span></label>
			<div><p>Multi-select notes and features</p></div>
		</li>
		<li class="flex" data-done data-type="todo_item">
			<label><input type="checkbox" checked="checked" /><span></span></label>
			<div><p>Multi-select notes and features</p></div>
		</li>
		<li class="flex" data-done data-type="todo_item">
			<label><input type="checkbox" checked="checked" /><span></span></label>
			<div><p>Multi-select notes and features</p></div>
		</li>
		<li class="flex" data-done data-type="todo_item">
			<label><input type="checkbox" checked="checked" /><span></span></label>
			<div><p>Multi-select notes and features</p></div>
		</li>
		<li class="flex" data-done data-type="todo_item">
			<label><input type="checkbox" checked="checked" /><span></span></label>
			<div><p>Multi-select notes and features</p></div>
		</li>
	</ul>
</div>`
