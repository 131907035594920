import React from "react"

import type { InboxState } from "@/lib/global-state"
import { useCompanies, useContacts } from "@/lib/replicache"
import {
	useAssignedToMeThreads,
	useCloseLoopThreads,
	useInboxThreads,
	useNewThreads,
	useProcessedThreads,
	useSnoozedThreads,
} from "../../inbox/useSortedInboxThreads"
import { useNotesFilterState } from "./notes-filter-state"

export function useFilteredNotes(noteState?: InboxState) {
	const companies = useCompanies()
	const contacts = useContacts()
	const {
		origins,
		creatorIds,
		segmentIds,
		painLevels,
		assigneeIds,
		projectIds,
		tagIds,
		issueIds,
		companyIds,
		personIds,
	} = useNotesFilterState()

	const inboxThreads = useInboxThreads()
	const snoozedThreads = useSnoozedThreads()
	const processedThreads = useProcessedThreads()
	const newThreads = useNewThreads()
	const assignedToMeThreads = useAssignedToMeThreads()
	const closeLoopThreads = useCloseLoopThreads()

	const filtered = React.useMemo(() => {
		let threads
		switch (noteState) {
			case "snoozed":
				threads = snoozedThreads
				break
			case "processed":
				threads = processedThreads
				break
			case "my":
				threads = assignedToMeThreads
				break
			case "close-loop":
				threads = closeLoopThreads
				break
			case "new":
				threads = newThreads
				break
			default:
				threads = inboxThreads
				break
		}
		const augmentedIssues = threads.map((i) => {
			const company = i.companyId
				? companies.find((c) => c.id === i.companyId)
				: null
			const contact = i.contactId
				? contacts.find((c) => c.id === i.contactId)
				: null
			const segmentIds = []
			if (company) {
				segmentIds.push(...company.segmentIds)
			}
			if (contact) {
				segmentIds.push(...contact.segmentIds)
			}
			return {
				...i,
				segmentIds,
			}
		})

		const filtered = augmentedIssues.filter(
			(i) =>
				(origins.length === 0 || origins.includes(i.origin)) &&
				(creatorIds.length === 0 || creatorIds.includes(i.reporterId ?? "")) &&
				(segmentIds.length === 0 ||
					segmentIds.some((id) => i.segmentIds.includes(id))) &&
				(painLevels.length === 0 || painLevels.includes(i.painLevel)) &&
				(assigneeIds.length === 0 || assigneeIds.includes(i.assigneeId)) &&
				(projectIds.length === 0 ||
					projectIds.some((id) =>
						id === null
							? i.linkedProjectIds.length === 0
							: i.linkedProjectIds.includes(id),
					)) &&
				(tagIds.length === 0 ||
					tagIds.some((id) =>
						id === null ? i.tagIds.length === 0 : i.tagIds.includes(id),
					)) &&
				(issueIds.length === 0 ||
					issueIds.some((id) =>
						id === null
							? i.linkedIssueIds.length === 0
							: i.linkedIssueIds.includes(id),
					)) &&
				(companyIds.length === 0 || companyIds.includes(i.companyId)) &&
				(personIds.length === 0 || personIds.includes(i.contactId)),
		)

		return filtered
	}, [
		assigneeIds,
		companies,
		companyIds,
		contacts,
		creatorIds,
		issueIds,
		projectIds,
		tagIds,
		noteState,
		origins,
		painLevels,
		personIds,
		segmentIds,
		inboxThreads,
		snoozedThreads,
		processedThreads,
		newThreads,
		assignedToMeThreads,
		closeLoopThreads,
	])
	return filtered
}
