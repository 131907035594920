export function sanitizeSubdomain(name: string) {
	if (name === "Productlane") {
		return "hello"
	}

	return name.trim().toLowerCase().replace(/ /g, "-").replace(/\./g, "")
}

export interface DomainSettings {
	domain: string
	customDomain?: string | null
	isGoogleAuthRequired: boolean
	googleDomain: string | null
	roadmapPublic?: boolean
	changelogPublic?: boolean
}

export function getHostname(workspace: DomainSettings): string {
	return workspace.customDomain
		? workspace.customDomain
		: `${workspace.domain}.productlane.com`
}

// TODO better regex because this only matches some popular domains, but there are infinite second-level domains
// https://stackoverflow.com/questions/24447839/more-than-one-top-level-domain
// httpspublicsuffix.org/lipublic_suffix_list.dat
export const domainRegex = /[0-9a-z-]+\.(?:(?:co|or|gv|ac|com)\.)?[a-z]{2,7}$/i

export const commonEmailProviders = [
	"facebook.com",
	"yahoo.com",
	"qq.com",
	"live.com",
	"msn.com",
	"go.com",
	"outlook.com",
	"free.fr",
	"163.com",
	"aol.com",
	"about.com",
	"indiatimes.com",
	"yandex.ru",
	"geocities.com",
	"alibaba.com",
	"example.com",
	"aliyun.com",
	"yahoo.co.jp",
	"netscape.com",
	"earthlink.net",
	"sky.com",
	"naver.com",
	"angelfire.com",
	"mail.ru",
	"aol.co.uk",
	"medscape.com",
	"uol.com.br",
	"gmail.com",
	"discovery.com",
	"spb.ru",
	"globo.com",
	"zoho.com",
	"space.com",
	"homestead.com",
	"frontier.com",
	"mac.com",
	"t-online.de",
	"yandex.com",
	"comcast.net",
	"lycos.com",
	"icloud.com",
	"bt.com",
	"altavista.com",
	"pp.ua",
	"orange.fr",
	"ancestry.com",
	"nus.edu.sg",
	"web.id",
	"rambler.ru",
	"msk.ru",
	"kansascity.com",
	"sapo.pt",
	"law.com",
	"me.com",
	"icq.com",
	"att.net",
	"india.com",
	"hotmail.com",
	"canada.com",
	"berlin.de",
	"libero.it",
	"daum.net",
	"test.com",
	"techspot.com",
	"fortunecity.com",
	"wanadoo.fr",
	"sina.cn",
	"docomo.ne.jp",
	"onet.pl",
	"skynet.be",
	"interia.pl",
	"care2.com",
	"telenet.be",
	"excite.co.jp",
	"terra.com.br",
	"anonymize.com",
	"compuserve.com",
	"zp.ua",
	"sina.com",
	"excite.com",
	"shaw.ca",
	"wp.pl",
	"web.de",
	"mindspring.com",
	"ig.com.br",
	"freeserve.co.uk",
	"geek.com",
	"rr.com",
	"ntlworld.com",
	"tom.com",
	"sympatico.ca",
	"online.de",
	"virgilio.it",
	"xoom.com",
	"virginmedia.com",
	"tiscali.it",
	"chez.com",
	"arcor.de",
	"cox.net",
	"iinet.net.au",
	"detik.com",
	"yourdomain.com",
	"blueyonder.co.uk",
	"yam.com",
	"sfr.fr",
	"gazeta.pl",
	"gmx.net",
	"doityourself.com",
	"rcn.com",
	"freenet.de",
	"proton.me",
	"catholic.org",
	"ya.ru",
	"verizon.net",
	"test.de",
	"btinternet.com",
	"r7.com",
	"21cn.com",
	"www.com",
	"protonmail.com",
	"rogers.com",
	"albawaba.com",
	"ireland.com",
	"ozemail.com.au",
	"virgin.net",
	"lycos.co.uk",
	"hotbot.com",
	"erols.com",
	"home.nl",
	"i.ua",
	"terra.es",
	"sanook.com",
	"seznam.cz",
	"chat.ru",
	"mail.com",
	"lycos.de",
	"charter.net",
	"sify.com",
	"planet.nl",
	"centrum.cz",
	"parrot.com",
	"o2.co.uk",
	"hidemyass.com",
	"rin.ru",
	"126.com",
	"voila.fr",
	"myway.com",
	"pochta.ru",
	"name.com",
	"blackplanet.com",
	"pe.hu",
	"ivillage.com",
	"netcom.com",
	"walla.co.il",
	"tiscali.co.uk",
	"hamptonroads.com",
	"telus.net",
	"mydomain.com",
	"alice.it",
	"chello.nl",
	"freeuk.com",
	"bluewin.ch",
	"oath.com",
	"dailypioneer.com",
	"tpg.com.au",
	"sci.fi",
	"club-internet.fr",
	"optusnet.com.au",
	"sweb.cz",
	"iespana.es",
	"lycos.es",
	"metacrawler.com",
	"poste.it",
	"kiwibox.com",
	"freeyellow.com",
	"nate.com",
	"lycos.nl",
	"webindia123.com",
	"bigpond.com",
	"inbox.com",
	"onmilwaukee.com",
	"prodigy.net",
	"foxmail.com",
	"eircom.net",
	"unican.es",
	"frontiernet.net",
	"looksmart.com",
	"gmx.com",
	"i.am",
	"wanadoo.es",
	"bugmenot.com",
	"mail2web.com",
	"za.com",
	"terra.com",
	"pobox.com",
	"cogeco.ca",
	"aim.com",
	"neuf.fr",
	"crosswinds.net",
	"hetnet.nl",
	"usa.com",
	"mail-tester.com",
	"10minutemail.com",
	"newmail.ru",
	"pacbell.net",
	"hot.ee",
	"yahoofs.com",
	"casino.com",
	"dejanews.com",
	"iol.it",
	"netins.net",
	"depechemode.com",
	"islamonline.net",
	"concentric.net",
	"iprimus.com.au",
	"mailinator.com",
	"yeah.net",
	"yahoo.co.uk",
	"ukr.net",
	"supereva.it",
	"netspace.net.au",
	"yahoo.jp",
	"bolt.com",
	"lycos.it",
	"sprynet.com",
	"idirect.com",
	"dmv.com",
	"elvis.com",
	"sdf.org",
	"go.ro",
	"spray.se",
	"hey.com",
	"bigfoot.com",
	"juno.com",
	"zip.net",
	"swissinfo.org",
	"o2.pl",
	"bellsouth.net",
	"talktalk.co.uk",
	"incredimail.com",
	"nyc.com",
	"fr.nf",
	"attbi.com",
	"bangkok.com",
	"starmedia.com",
	"westnet.com.au",
	"adelphia.net",
	"yahoo.com.cn",
	"bigpond.net.au",
	"zonnet.nl",
	"masrawy.com",
	"wow.com",
	"yahoo.fr",
	"msn.co.uk",
	"yahoo.de",
	"37.com",
	"scubadiving.com",
	"gportal.hu",
	"land.ru",
	"hushmail.com",
	"cu.cc",
	"thirdage.com",
	"interfree.it",
	"btconnect.com",
	"doctor.com",
	"seanet.com",
	"montevideo.com.uy",
	"forthnet.gr",
	"4mg.com",
	"optimum.net",
	"terra.cl",
	"cableone.net",
	"c3.hu",
	"yahoo.com.tw",
	"netzero.net",
	"temp-mail.org",
	"blogos.com",
	"roadrunner.com",
	"saudia.com",
	"fastmail.fm",
	"gmx.de",
	"aeiou.pt",
	"guerrillamail.com",
	"singnet.com.sg",
	"spacewar.com",
	"tds.net",
	"spamtrap.ro",
	"telstra.com",
	"sp.nl",
	"sbcglobal.net",
	"oi.com.br",
	"tin.it",
	"ny.com",
	"bright.net",
	"mchsi.com",
	"iwon.com",
	"hispavista.com",
	"webjump.com",
	"ananzi.co.za",
	"cs.com",
	"us.to",
	"yahoo.com.br",
	"talkcity.com",
	"c2i.net",
	"terra.com.ar",
	"btopenworld.com",
	"singpost.com",
	"airmail.net",
	"qwest.net",
	"freeola.com",
	"barcelona.com",
	"front.ru",
	"dnsmadeeasy.com",
	"games.com",
	"maktoob.com",
	"everyone.net",
	"3ammagazine.com",
	"ptd.net",
	"asheville.com",
	"dog.com",
	"talktalk.net",
	"pipeline.com",
	"excite.it",
	"wowway.com",
	"lawyer.com",
	"abv.bg",
	"windstream.net",
	"hotmail.ru",
	"fuse.net",
	"mailbox.org",
	"recycler.com",
	"zzn.com",
	"bizhosting.com",
	"yahoo.cn",
	"netscape.net",
	"beer.com",
	"swbell.net",
	"bellatlantic.net",
	"dynu.net",
	"inbox.lv",
	"tiscali.be",
	"go2net.com",
	"yahoo.ca",
	"conexcol.com",
	"windowslive.com",
	"she.com",
	"home.ro",
	"yahoo.es",
	"dslextreme.com",
	"vnn.vn",
	"epix.net",
	"bestweb.net",
	"yopmail.com",
	"onlinehome.de",
	"freeuk.net",
	"enter.net",
	"charm.net",
	"dropzone.com",
	"garbage.com",
	"ymail.com",
	"ados.fr",
	"laposte.net",
	"easypost.com",
	"aver.com",
	"chacuo.net",
]
