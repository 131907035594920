import { useMemo } from "react"
import { parseISO } from "date-fns"
import * as R from "remeda"

import { useTags, useThreads } from "@/lib/replicache"
import { useEntityById } from "./useEntityById"
import { useRouteId } from "./useRouteId"

export const useAugmentedTags = () => {
	const id = useRouteId()
	const threads = useThreads()
	const tags = useTags()
	const tag = useEntityById(tags, id)
	const augmentedTagNotes = useMemo(
		() =>
			tag
				? R.pipe(
						threads,
						R.filter((thread) => tag.feedbackIds.includes(thread.id)),
						R.sortBy([(thread) => parseISO(thread.createdAtIso), "desc"]),
					)
				: [],
		[tag, threads],
	)

	return {
		augmentedTagNotes,
	}
}
