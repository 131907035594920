import type { ThreadDeleteBulk } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function threadDeleteBulk({
	tx,
	args,
	workspaceId,
}: MutationContext<ThreadDeleteBulk>) {
	const keys = args.ids.map((id) => `${workspaceId}/threads/${id}`)
	await Promise.all(keys.map((key) => tx.del(key)))
}
