import type { ReadonlyJSONValue } from "replicache"

import type { TagGroupGet, TagGroupUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function tagGroupUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<TagGroupUpdate>) {
	const key = `${workspaceId}/tagGroups/${args.id}`

	const prev = (await tx.get(key)) as unknown as TagGroupGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("TagGroup not found to update")
	}

	const newTagGroup: TagGroupGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newTagGroup as unknown as ReadonlyJSONValue)
}
