import type { ReadonlyJSONValue } from "replicache"

import type {
	CompaniesGet,
	CompanyUpdate,
	ContactsGet,
	SegmentsGet,
} from "@productlane/api"

import type { MutationContext } from "../../types"
import { threadsGet } from "../threads/get"
import { threadUpdate } from "../threads/update"

export async function companyUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<CompanyUpdate>) {
	const key = `${workspaceId}/companies/${args.id}`

	const prev = (await tx.get(key)) as unknown as CompaniesGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Company not found to update")
	}

	const newCompany: CompaniesGet[number] = {
		...prev,
		contactIds: args.contactIds ?? prev.contactIds,
		domain: args.domain ?? prev.domain,
		name: args.name ?? prev.name,
		segmentIds: args.segmentIds ?? prev.segmentIds,
	}

	if (args.segmentIds) {
		const segments = (await tx
			.scan({
				prefix: `${workspaceId}/segments/`,
			})
			.values()
			.toArray()) as unknown as SegmentsGet
		const segmentsToUpdate = segments.filter(
			(s) =>
				(args.segmentIds?.includes(s.id) && !prev.segmentIds.includes(s.id)) ??
				(prev.segmentIds.includes(s.id) && !args.segmentIds?.includes(s.id)),
		)
		for (const segment of segmentsToUpdate) {
			if (
				segment.companyIds.includes(args.id) &&
				!args.segmentIds.includes(segment.id)
			) {
				// remove company
				const payload: SegmentsGet[number] = {
					...segment,
					companyIds: segment.companyIds.filter((cId) => cId !== args.id),
				}
				await tx.set(
					`${workspaceId}/segments/${segment.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			} else {
				// add company
				const payload: SegmentsGet[number] = {
					...segment,
					companyIds: [...segment.companyIds, args.id],
				}
				await tx.set(
					`${workspaceId}/segments/${segment.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			}
		}
	}

	if (args.contactIds) {
		const contacts = (await tx
			.scan({
				prefix: `${workspaceId}/contacts/`,
			})
			.values()
			.toArray()) as unknown as ContactsGet
		const contactsToUpdate = contacts.filter(
			(c) =>
				(args.contactIds?.includes(c.id) && !prev.contactIds.includes(c.id)) ||
				(prev.contactIds.includes(c.id) && !args.contactIds?.includes(c.id)),
		)
		const notes = await threadsGet({ args: {}, tx, workspaceId })
		for (const contact of contactsToUpdate) {
			const contactNotes = notes.filter((n) => n.contactId === contact.id)
			if (contact.companyId === prev.id) {
				// remove company
				for (const note of contactNotes) {
					await threadUpdate({
						tx,
						workspaceId,
						args: { id: note.id, companyId: null },
					})
				}
				const payload: ContactsGet[number] = {
					...contact,
					companyId: null,
				}
				await tx.set(
					`${workspaceId}/contacts/${contact.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			} else {
				for (const note of contactNotes) {
					await threadUpdate({
						tx,
						workspaceId,
						args: { id: note.id, companyId: prev.id },
					})
				}
				// add company
				const payload: ContactsGet[number] = {
					...contact,
					companyId: prev.id,
				}
				await tx.set(
					`${workspaceId}/contacts/${contact.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			}
		}
	}

	return await tx.set(key, newCompany as unknown as ReadonlyJSONValue)
}
