import type { ReadonlyJSONValue } from "replicache"

import type { ChangelogsGet, ChangelogUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function changelogUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<ChangelogUpdate>) {
	const key = `${workspaceId}/changelogs/${args.id}`

	const prev = (await tx.get(key)) as unknown as ChangelogsGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Changelog not found to update")
	}

	const newChangelog: ChangelogsGet[number] = {
		...prev,
		createdAtIso: args.dateIso ? args.dateIso : prev.createdAtIso,
		dateIso: args.dateIso ? args.dateIso : prev.dateIso ? prev.dateIso : null,
		archived: args.archived ?? prev.archived,
		imageUrl:
			args.imageUrl === null
				? null
				: args.imageUrl
					? args.imageUrl
					: prev.imageUrl,
		notes: args.notes ? args.notes : prev.notes,
		published: args.published ? args.published : prev.published,
		title: args.title ? args.title : prev.title,
	}

	return tx.set(key, newChangelog as unknown as ReadonlyJSONValue)
}
