import type { Transcript, TranscriptLanguageCode } from "assemblyai"

interface AssemblyLanguageConfig {
	label: string
	value: TranscriptLanguageCode | "da"
	language_detection: boolean
	profanity_filter: boolean
	auto_highlights: boolean
	speaker_labels: boolean
	speech_model?: Transcript["speech_model"]
}

export const ASSEMBLY_AI_LANGUAGES: AssemblyLanguageConfig[] = [
	{
		label: "English",
		value: "en",
		language_detection: true,
		profanity_filter: true,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Spanish",
		value: "es",
		language_detection: true,
		profanity_filter: true,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Danish",
		value: "da",
		language_detection: true,
		profanity_filter: false,
		auto_highlights: false,
		speaker_labels: false,
		speech_model: "nano",
	},
	{
		label: "French",
		value: "fr",
		language_detection: true,
		profanity_filter: true,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "German",
		value: "de",
		language_detection: true,
		profanity_filter: true,
		auto_highlights: false,
		speaker_labels: true,
	},
	{
		label: "Italian",
		value: "it",
		language_detection: true,
		profanity_filter: true,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Portuguese",
		value: "pt",
		language_detection: true,
		profanity_filter: true,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Dutch",
		value: "nl",
		language_detection: true,
		profanity_filter: false,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Hindi",
		value: "hi",
		language_detection: false,
		profanity_filter: true,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Japanese",
		value: "ja",
		language_detection: false,
		profanity_filter: true,
		auto_highlights: false,
		speaker_labels: false,
	},
	{
		label: "Chinese",
		value: "zh",
		language_detection: false,
		profanity_filter: false,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Finnish",
		value: "fi",
		language_detection: false,
		profanity_filter: false,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Korean",
		value: "ko",
		language_detection: false,
		profanity_filter: false,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Polish",
		value: "pl",
		language_detection: false,
		profanity_filter: false,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Russian",
		value: "ru",
		language_detection: false,
		profanity_filter: false,
		auto_highlights: false,
		speaker_labels: true,
	},
	{
		label: "Turkish",
		value: "tr",
		language_detection: false,
		profanity_filter: false,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Ukrainian",
		value: "uk",
		language_detection: false,
		profanity_filter: false,
		auto_highlights: true,
		speaker_labels: true,
	},
	{
		label: "Vietnamese",
		value: "vi",
		language_detection: false,
		profanity_filter: false,
		auto_highlights: true,
		speaker_labels: true,
	},
] as const

export const languageCodeValid = (languageCode: string) => {
	return ASSEMBLY_AI_LANGUAGES.some(({ value }) => value === languageCode)
}

export const getLanguagesWithLanguageDetection = () => {
	return ASSEMBLY_AI_LANGUAGES.filter(
		({ language_detection }) => language_detection,
	).map(({ label }) => label)
}

export const getLanguagesWithAutoHighlights = () => {
	return ASSEMBLY_AI_LANGUAGES.filter(({ auto_highlights }) => auto_highlights)
}
export const getLanguagesWithSpeakerLabels = () => {
	return ASSEMBLY_AI_LANGUAGES.filter(({ speaker_labels }) => speaker_labels)
}

export const getLanguagesWithProfanityFilter = () => {
	return ASSEMBLY_AI_LANGUAGES.filter(
		({ profanity_filter }) => profanity_filter,
	).map(({ label }) => label)
}

export const getSpeechModelFromLanguage = (languageCode: string) =>
	ASSEMBLY_AI_LANGUAGES.find(({ value }) => value === languageCode)
		?.speech_model || "best"

export const getProfanityFilterFromLanguage = (languageCode: string) =>
	ASSEMBLY_AI_LANGUAGES.find(({ value }) => value === languageCode)
		?.profanity_filter ?? true

export type AssemblyAILanguages =
	(typeof ASSEMBLY_AI_LANGUAGES)[number]["value"]
