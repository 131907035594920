/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeAttributes, Node } from "@tiptap/core"

export interface TaskListOptions {
	itemTypeName: string
	HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		taskList: {
			/**
			 * Toggle a task list
			 */
			toggleTaskList: () => ReturnType
		}
	}
}

export const CustomTaskListExtension = Node.create<TaskListOptions>({
	name: "todo_list",

	addOptions() {
		return {
			itemTypeName: "todo_item",
			HTMLAttributes: {
				class: "p-0",
			},
		}
	},

	group: "block list",

	content() {
		return `${this.options.itemTypeName}+`
	},

	parseHTML() {
		return [
			{
				tag: `ul[data-type="${this.name}"]`,
				priority: 51,
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return [
			"ul",
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
				"data-type": this.name,
			}),
			0,
		]
	},

	addCommands() {
		return {
			toggleTaskList:
				() =>
				({ commands }) => {
					return commands.toggleList(this.name, this.options.itemTypeName)
				},
		}
	},

	addKeyboardShortcuts() {
		return {
			"Mod-Shift-9": () => this.editor.commands.toggleTaskList(),
		}
	},
})
