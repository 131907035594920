import { useCallback, useMemo } from "react"

import { useHighlights } from "@/components/editor/editor-config"
import { useUtterances } from "@/lib/replicache"
import { useThreadContext } from "../thread-context"

// The transcript component is virtualized so we can't just scroll the elements into view as some of them might not exist yet
// That's why we need to look up any utterances and use the virtualizer to scroll them into view
// For highlights in the normal note we can just scroll them into view
export function useScrollClick() {
	const {
		thread,
		transcriptAccordionValue,
		setTranscriptAccordionValue,
		transcriptVirtualizer,
	} = useThreadContext()
	const { clickCount, setClickCount } = useHighlights()

	const utterances = useUtterances()
	const threadUtterances = useMemo(() => {
		return utterances
			.filter((u) => u.videoId === thread.videoId)
			.sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
	}, [thread.videoId, utterances])

	const onClick = useCallback(
		(id: string) => {
			const utterancesForId = threadUtterances.filter((u) =>
				u.text.includes(id),
			)
			const marks = document.querySelectorAll(
				`#editor-content mark[data-id="${id}"]`,
			)
			const elements = [...utterancesForId, ...marks]
			const targetEl = elements[clickCount]
			if (targetEl) {
				if ("text" in targetEl) {
					if (!transcriptAccordionValue) {
						// if the transcript is closed we first need to open it and make the user click again because the transcriptVirtualizer
						// is not yet defined in that case
						setTranscriptAccordionValue("transcript-dropdown")
						return
					}
					if (transcriptVirtualizer?.v) {
						const utteranceIndex = threadUtterances.findIndex(
							(u) => u.id === targetEl.id,
						)

						transcriptVirtualizer.v.scrollToIndex(utteranceIndex, {
							align: "start",
						})
						// give the virtualizer a bit to finish scrolling, then adjust the parent scroll container
						setTimeout(() => {
							const scrollElement = document.querySelector(
								`[data-index="${utteranceIndex}"] mark[data-id="${id}"]`,
							)
							scrollElement?.scrollIntoView({
								behavior: "smooth",
								block: "center",
							})
						}, 100)
					}
				} else {
					targetEl.scrollIntoView({
						behavior: "smooth",
						block: "center",
					})
				}
				setClickCount(clickCount === elements.length - 1 ? 0 : clickCount + 1)
			}
			return
		},
		[
			clickCount,
			setClickCount,
			setTranscriptAccordionValue,
			threadUtterances,
			transcriptAccordionValue,
			transcriptVirtualizer?.v,
		],
	)
	return {
		onClick,
	}
}
