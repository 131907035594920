import type { ReadonlyJSONValue } from "replicache"

import type { CustomerGet, CustomerUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function customerUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<CustomerUpdate>) {
	const key = `${workspaceId}/customers/${args.id}`

	const prev = (await tx.get(key)) as unknown as CustomerGet | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Customer not found to update")
	}

	const newCustomer: CustomerGet = {
		...prev,
		...(args.name ? { name: args.name } : {}),
		...(args.size || args.size === 0 ? { size: args.size } : {}),
		...(args.revenue || args.revenue === 0 ? { revenue: args.revenue } : {}),
		...(args.tierId ? { tierId: args.tierId } : {}),
		...(args.tierName ? { tierName: args.tierName } : {}),
		...(args.statusId ? { statusId: args.statusId } : {}),
		...(args.statusName ? { statusName: args.statusName } : {}),
		...(args.statusColor ? { statusColor: args.statusColor } : {}),
	}

	return await tx.set(key, newCustomer as unknown as ReadonlyJSONValue)
}
