import type { User } from "@productlane/db"

import type {
	CommentsGet,
	ContactsGet,
	EmailsGet,
	IssuesGet,
	ProjectsGet,
	SlackMessagesGet,
	SlackUsersGet,
	ThreadMessagesGet,
	ThreadsGet,
} from "../replicache/types"
import { EmailContactSchema } from "./email"

export type TimelineEvent =
	| {
			type: "createdAt"
			thread: ThreadsGet[number] & {
				creatorName?: string
			}
	  }
	| {
			type: "email"
			email: EmailsGet[number] & {
				fromContactName: string
				fromContactImage?: string | null
				fromContactId?: string | null
				isLast: boolean
			}
	  }
	| {
			type: "comment"
			comment: CommentsGet[number] & {
				userName?: string | null
				userImage?: string | null
			}
	  }
	| {
			type: "threadMessage"
			threadMessage: ThreadMessagesGet[number]
	  }
	| {
			type: "issue-completed"
			issue: IssuesGet[number]
	  }
	| {
			type: "project-completed"
			project: ProjectsGet[number]
	  }
	| {
			type: "slackMessage"
			slackMessage: SlackMessagesGet[number] & {
				fromContactName: string
				fromContactImage?: string | null
				fromContactId?: string | null
			}
	  }

// emails, comments etc. should be pre-filtered by thread
export function getTimelineEvents({
	thread,
	emails,
	comments,
	contacts,
	threadMessages,
	slackMessages,
	slackUsers,
	issues,
	projects,
	users,
}: {
	thread: ThreadsGet[number]
	emails: EmailsGet
	comments: CommentsGet
	contacts: ContactsGet
	threadMessages: ThreadMessagesGet
	slackMessages: SlackMessagesGet
	slackUsers: SlackUsersGet
	issues: IssuesGet
	projects: ProjectsGet
	users: Array<Pick<User, "id" | "name" | "imageUrl">>
}) {
	const createdEvent: TimelineEvent = {
		type: "createdAt",
		thread: {
			...thread,
			creatorName:
				users.find((u) => u.id === thread.reporterId)?.name ?? undefined,
		},
	}
	const emailEvents: TimelineEvent[] = emails
		.filter((email) => email.threadId === thread.id)
		.map((email, index, emails) => {
			let fromContactName
			let fromContactImage
			let fromContactId
			const parsedFrom = EmailContactSchema.parse(email.from)
			if (email.type === "OUTBOUND") {
				const user = users.find((u) => u.id === email.userId)
				fromContactName = user?.name
				fromContactImage = user?.imageUrl
				fromContactId = user?.id
			} else {
				const contact = contacts.find((c) => c.email === parsedFrom.email)
				fromContactName = contact?.name
				fromContactImage = contact?.imageUrl
			}
			return {
				type: "email",
				email: {
					...email,
					isLast: index === emails.length - 1,
					fromContactName: fromContactName
						? fromContactName
						: parsedFrom.name
							? parsedFrom.name
							: parsedFrom.email,
					fromContactImage,
					fromContactId,
				},
			}
		})

	const commentEvents: TimelineEvent[] = comments
		.filter((comment) => comment.feedbackId === thread.id)
		.map((comment) => {
			const user = users.find((u) => u.id === comment.userId)
			return {
				type: "comment",
				comment: {
					...comment,
					userName: user?.name,
					userImage: user?.imageUrl,
				},
			}
		})

	const threadMessageEvents: TimelineEvent[] = threadMessages
		.filter((threadMessage) => threadMessage.threadId === thread.id)
		.map((threadMessage) => {
			return {
				type: "threadMessage",
				threadMessage: threadMessage,
			}
		})

	const slackMessageEvents: TimelineEvent[] = slackMessages
		.filter((slackMessage) => slackMessage.threadId === thread.id)
		.map((slackMessage) => {
			let fromContactName
			let fromContactImage
			let fromContactId
			if (slackMessage.type === "OUTBOUND") {
				const user = users.find((u) => u.id === slackMessage.userId)
				if (user) {
					fromContactName = user.name
					fromContactImage = user.imageUrl
					fromContactId = user.id
				} else {
					// fall back to slack users
					// this can happen when someone replies directly in slack, then the message
					// is "outbound" but the internal userId won't be set because it wasn't sent through Productlane
					const contact = slackUsers.find(
						(c) => c.slackId === slackMessage.slackUserId,
					)
					fromContactName = contact?.name ?? contact?.username
					fromContactImage = contact?.imageUrl
				}
			} else {
				const contact = slackUsers.find(
					(c) => c.slackId === slackMessage.slackUserId,
				)
				fromContactName = contact?.name ?? contact?.username
				fromContactImage = contact?.imageUrl
			}
			return {
				type: "slackMessage",
				slackMessage: {
					...slackMessage,
					fromContactName: fromContactName ? fromContactName : "Unknown user",
					fromContactId,
					fromContactImage,
				},
			}
		})

	const issueEvents: TimelineEvent[] = issues
		.filter((i) => thread.linkedIssueIds.includes(i.id) && i.completedAtIso)
		.map((issue) => {
			const e: TimelineEvent = {
				type: "issue-completed",
				issue,
			}
			return e
		})

	const projectEvents: TimelineEvent[] = projects
		.filter((p) => thread.linkedProjectIds.includes(p.id) && p.completedAtIso)
		.map((project) => {
			const e: TimelineEvent = {
				type: "project-completed",
				project,
			}
			return e
		})

	const allEvents = [
		createdEvent,
		...emailEvents,
		...commentEvents,
		...threadMessageEvents,
		...slackMessageEvents,
		...issueEvents,
		...projectEvents,
	]

	allEvents.sort((a, b) => {
		let dateA, dateB
		switch (a.type) {
			case "createdAt":
				dateA = new Date(a.thread.createdAtIso)
				break
			case "email":
				dateA = new Date(a.email.createdAtIso)
				break
			case "comment":
				dateA = new Date(a.comment.createdAtIso)
				break
			case "threadMessage":
				dateA = new Date(a.threadMessage.createdAtIso)
				break
			case "slackMessage":
				dateA = new Date(a.slackMessage.createdAtIso)
				break
			case "issue-completed":
				// this should always be defined, so this is just for safetey because we filter the issue above for completedAt
				dateA = a.issue.completedAtIso
					? new Date(a.issue.completedAtIso)
					: new Date()
				break
			case "project-completed":
				// same
				dateA = a.project.completedAtIso
					? new Date(a.project.completedAtIso)
					: new Date()
				break
			default:
				dateA = new Date()
		}
		switch (b.type) {
			case "createdAt":
				dateB = new Date(b.thread.createdAtIso)
				break
			case "email":
				dateB = new Date(b.email.createdAtIso)
				break
			case "comment":
				dateB = new Date(b.comment.createdAtIso)
				break
			case "threadMessage":
				dateB = new Date(b.threadMessage.createdAtIso)
				break
			case "slackMessage":
				dateB = new Date(b.slackMessage.createdAtIso)
				break
			case "issue-completed":
				// this should always be defined, so this is just for safetey because we filter the issue above for completedAt
				dateB = b.issue.completedAtIso
					? new Date(b.issue.completedAtIso)
					: new Date()
				break
			case "project-completed":
				// this should always be defined, so this is just for safetey because we filter the issue above for completedAt
				dateB = b.project.completedAtIso
					? new Date(b.project.completedAtIso)
					: new Date()
				break
			default:
				dateB = new Date()
		}
		return dateA.getTime() - dateB.getTime()
	})

	return allEvents
}
