import { ReactRenderer } from "@tiptap/react"

import type { SnippetInput } from "@/screens/settings/snippets-settings-page.tsx"
import type { SuggestionOptions } from "@tiptap/suggestion"
import SnippetSuggestions from "./snippet-suggestions.tsx"

export default {
	allowSpaces: true,
	char: ";",
	deleteTriggerWithBackspace: true,
	command: ({ editor, range, props }) => {
		editor.commands.insertContentAt(
			{ from: range.from - 1, to: range.to },
			(props as SnippetInput).html,
		)
		editor.commands.focus()
	},
	render: () => {
		let reactRenderer: ReactRenderer

		return {
			onStart: (props) => {
				reactRenderer = new ReactRenderer(SnippetSuggestions, {
					props,
					editor: props.editor,
				})
			},

			onUpdate(props) {
				reactRenderer?.updateProps(props)
			},

			onKeyDown(props) {
				if (props.event.key === "Escape") {
					reactRenderer?.destroy()

					return true
				}

				// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
				return (reactRenderer?.ref as any)?.onKeyDown(props)
			},

			onExit() {
				reactRenderer.destroy()
			},
		}
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as Omit<SuggestionOptions<any>, "editor">
