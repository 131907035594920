import type { ReadonlyJSONValue } from "replicache"

import type { SegmentsGet, SegmentUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function segmentUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<SegmentUpdate>) {
	const key = `${workspaceId}/segments/${args.id}`

	const prev = (await tx.get(key)) as unknown as SegmentsGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Segment not found to update")
	}

	const newSegment: SegmentsGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newSegment as unknown as ReadonlyJSONValue)
}
