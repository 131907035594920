/* eslint-disable no-inner-declarations */
import type { Replicache } from "replicache"
import { useEffect } from "react"
import { io } from "socket.io-client"

import { env } from "@/env"
import { useSession } from "./session"

export function usePokeListener({ rep }: { rep: Replicache | null }) {
	const { session } = useSession()

	useEffect(() => {
		if (rep && session?.workspaceId) {
			const socket = io(env.VITE_LIVE_URL, {
				path: "/io",
			})

			socket.io.on("error", (err) => {
				console.log("socket.io error:", err)
			})

			console.log("setting up websocket")

			function onConnect() {
				console.log("🟢 Live server connected")
			}
			function onPoke() {
				console.log("👇 Received poke")
				void rep?.pull()
			}
			socket.on("connect", onConnect)
			socket.on(`poke:${session.workspaceId}`, onPoke)

			return () => {
				socket.off("connect", onConnect)
				socket.off(`poke:${session.workspaceId}`, onPoke)
			}
		}
	}, [session?.workspaceId, rep])
}
