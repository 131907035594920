import type { SnippetDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function snippetDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<SnippetDelete>) {
	return tx.del(`${workspaceId}/snippets/${args.id}`)
}
