import type { ReadonlyJSONValue } from "replicache"

import type { NoteTemplatesGet, NoteTemplateUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function noteTemplateUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<NoteTemplateUpdate>) {
	const key = `${workspaceId}/noteTemplates/${args.id}`

	const prev = (await tx.get(key)) as unknown as NoteTemplatesGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Note template not found to update.")
	}

	const newNoteTemplate: NoteTemplatesGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newNoteTemplate as unknown as ReadonlyJSONValue)
}
