import { create } from "zustand"

interface SyncState {
	syncing: boolean
	setSyncing: (type: boolean) => void
}

export const useReplicacheSync = create<SyncState>()((set, get) => ({
	syncing: false,
	setSyncing: (value) => {
		const prev = get().syncing
		if (value && !prev) {
			console.log("Syncing...")
		} else if (!value && prev) {
			console.log("Done syncing.")
		}
		set({ syncing: value })
	},
}))
