import type { ThreadsGet, ThreadUpdateBulk } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function threadUpdateBulk({
	tx,
	args,
	workspaceId,
}: MutationContext<ThreadUpdateBulk>) {
	const keys = args.ids.map((id) => `${workspaceId}/threads/${id}`)
	const threads = (await Promise.all(
		keys.map((key) => tx.get(key)),
	)) as ThreadsGet
	await Promise.all(
		threads.map((thread) => {
			const newThread: ThreadsGet[number] = {
				...thread,
				state: args.state ?? thread.state,
				...(args.lastStateChangeAtIso
					? { lastStateChangeAtIso: args.lastStateChangeAtIso }
					: {}),
			}
			return tx.set(`${workspaceId}/threads/${thread.id}`, newThread)
		}),
	)
}
