import type { ReadonlyJSONValue } from "replicache"

import type { ContactsGet, ContactUpdate, SegmentsGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function contactUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<ContactUpdate>) {
	const key = `${workspaceId}/contacts/${args.id}`

	const prev = (await tx.get(key)) as unknown as ContactsGet[number] | null

	if (!prev || typeof prev !== "object") {
		throw new Error("Contact not found to update")
	}

	const newContact: ContactsGet[number] = {
		...prev,
		companyId: args.companyId ?? prev.companyId,
		email: args.email ?? prev.email,
		name: args.name ?? prev.name,
		segmentIds: args.segmentIds ?? prev.segmentIds,
	}

	if (args.segmentIds) {
		const segments = (await tx
			.scan({
				prefix: `${workspaceId}/segments/`,
			})
			.values()
			.toArray()) as unknown as SegmentsGet
		const segmentsToUpdate = segments.filter(
			(s) =>
				(args.segmentIds?.includes(s.id) && !prev.segmentIds.includes(s.id)) ??
				(prev.segmentIds.includes(s.id) && !args.segmentIds?.includes(s.id)),
		)
		for (const segment of segmentsToUpdate) {
			if (segment.contactIds.includes(args.id)) {
				// remove contact
				const payload: SegmentsGet[number] = {
					...segment,
					contactIds: segment.contactIds.filter((cId) => cId !== args.id),
				}
				await tx.set(
					`${workspaceId}/segments/${segment.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			} else {
				// add contact
				const payload: SegmentsGet[number] = {
					...segment,
					contactIds: [...segment.contactIds, args.id],
				}
				await tx.set(
					`${workspaceId}/segments/${segment.id}`,
					payload as unknown as ReadonlyJSONValue,
				)
			}
		}
	}

	return await tx.set(key, newContact as unknown as ReadonlyJSONValue)
}
