import type { ReadonlyJSONValue } from "replicache"

import type { SidebarSectionCreate, SidebarSectionGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function sidebarSectionCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<SidebarSectionCreate>) {
	const key = `${workspaceId}/sidebarSections/${args.id}`
	if (await tx.has(key)) {
		throw new Error("Sidebar section already exists")
	}

	const now = new Date()

	const data: SidebarSectionGet[number] = {
		id: args.id,
		createdAtIso: now.toISOString(),
		workspaceId,
		title: args.title,
		url: args.url,
		headers: args.headers,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
