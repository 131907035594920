import { isBefore, parseISO, sub } from "date-fns"
import * as R from "remeda"

import { PainLevel } from "@productlane/db/browser"

import type {
	IssuesGet,
	IssueUpvotesGet,
	ProjectsGet,
	TagsGet,
	ThreadsGet,
	UpvotesGet,
} from "../replicache/types"

export interface PainLevelStatistics {
	upvoteCount: number | null
	feedbacks: Record<PainLevel, number>
}

export const DEFAULT_PAIN_LEVEL_STATISTICS: PainLevelStatistics = {
	upvoteCount: 0,
	feedbacks: {
		HIGH: 0,
		MEDIUM: 0,
		LOW: 0,
		UNKNOWN: 0,
	},
}
export function getPainLevelNumericalValue(p: PainLevel) {
	switch (p) {
		case PainLevel.HIGH:
			return 4

		case PainLevel.MEDIUM:
			return 3

		case PainLevel.LOW:
			return 2

		case PainLevel.UNKNOWN:
			return 1

		default:
			throw new Error("Trying to map unknown Pain Level")
	}
}

export function getMaxPainLevelForTags() {
	return
}

export function getTagPainLevel(tag: TagsGet[number], feedbacks: ThreadsGet) {
	const painLevelStatistics: PainLevelStatistics = {
		upvoteCount: null,
		feedbacks: {
			HIGH: 0,
			MEDIUM: 0,
			LOW: 0,
			UNKNOWN: 0,
		},
	}

	const tagFeedbacks = feedbacks.filter((f) => f.tagIds.includes(tag.id))
	let totalFeedbackCount = 0
	let feedbackCountFourWeeksAgo = 0
	for (const feedback of tagFeedbacks) {
		const numericalPainLevel = getPainLevelNumericalValue(feedback.painLevel)
		totalFeedbackCount += numericalPainLevel

		painLevelStatistics.feedbacks[feedback.painLevel] += 1
		if (
			isBefore(parseISO(feedback.createdAtIso), sub(new Date(), { weeks: 4 }))
		) {
			feedbackCountFourWeeksAgo += numericalPainLevel
		}
	}
	const trend = totalFeedbackCount - feedbackCountFourWeeksAgo
	const painLevel = R.sumBy(tagFeedbacks, (x) =>
		getPainLevelNumericalValue(x.painLevel),
	)
	return {
		trend,
		painLevel,
		painLevelStatistics,
	}
}

export function getIssuePainLevel(
	issue: Pick<IssuesGet[number], "id">,
	feedbacks: Array<
		Pick<
			ThreadsGet[number],
			"id" | "linkedIssueIds" | "createdAtIso" | "painLevel"
		>
	>,
	upvotes: Array<
		Pick<IssueUpvotesGet[number], "id" | "issueId" | "createdAtIso">
	>,
) {
	const issueFeedbacks = feedbacks.filter((f) =>
		f.linkedIssueIds.includes(issue.id),
	)
	const issueUpvotes = upvotes.filter((u) => u.issueId === issue.id)

	const painLevelStatistics: PainLevelStatistics = {
		upvoteCount: 0,
		feedbacks: {
			HIGH: 0,
			MEDIUM: 0,
			LOW: 0,
			UNKNOWN: 0,
		},
	}

	painLevelStatistics.upvoteCount = issueUpvotes.length

	let upvoteCountFourWeeksAgo = 0
	for (const upvote of issueUpvotes) {
		if (
			isBefore(parseISO(upvote.createdAtIso), sub(new Date(), { weeks: 4 }))
		) {
			upvoteCountFourWeeksAgo++
		}
	}
	let totalFeedbackCount = 0
	let feedbackCountFourWeeksAgo = 0
	for (const feedback of issueFeedbacks) {
		const numericalPainLevel = getPainLevelNumericalValue(feedback.painLevel)
		totalFeedbackCount += numericalPainLevel

		painLevelStatistics.feedbacks[feedback.painLevel] += 1
		if (
			isBefore(parseISO(feedback.createdAtIso), sub(new Date(), { weeks: 4 }))
		) {
			feedbackCountFourWeeksAgo += numericalPainLevel
		}
	}
	const trend =
		totalFeedbackCount -
		feedbackCountFourWeeksAgo +
		(painLevelStatistics.upvoteCount - upvoteCountFourWeeksAgo)
	const painLevel =
		R.sumBy(issueFeedbacks, (x) => getPainLevelNumericalValue(x.painLevel)) +
		painLevelStatistics.upvoteCount

	// we also need to make a statistics element that looks like this {upvoteCount: number, feedbacks: {critical: number, high: number, medium: number, low: number}}

	return {
		trend,
		painLevel,
		painLevelStatistics,
	}
}

export function getProjectPainLevel(
	// this needs to work for both client side data from replicache as well as server side data from prisma directly for the roadmap
	project: Pick<ProjectsGet[number], "id" | "linearProjectId">,
	feedbacks: Array<
		Pick<
			ThreadsGet[number],
			| "id"
			| "linkedProjectIds"
			| "linkedIssueIds"
			| "createdAtIso"
			| "painLevel"
		>
	>,
	upvotes: Array<Pick<UpvotesGet[number], "id" | "projectId" | "createdAtIso">>,
	issues: Array<Pick<IssuesGet[number], "id" | "projectId">>,
	issueUpvotes: Array<
		Pick<IssueUpvotesGet[number], "id" | "issueId" | "createdAtIso">
	>,
) {
	const issuePainLevels = issues
		.filter((i) => i.projectId === project.linearProjectId)
		.map((i) => getIssuePainLevel(i, feedbacks, issueUpvotes))
	const issueSumPain = R.sumBy(issuePainLevels, (x) => x.painLevel)
	const issueSumTrend = R.sumBy(issuePainLevels, (x) => x.trend)

	const projectFeedbacks = feedbacks.filter((f) =>
		f.linkedProjectIds.includes(project.id),
	)

	const painLevelStatistics: PainLevelStatistics = {
		upvoteCount: 0,
		feedbacks: {
			HIGH: 0,
			MEDIUM: 0,
			LOW: 0,
			UNKNOWN: 0,
		},
	}

	const projectUpvotes = upvotes.filter((u) => u.projectId === project.id)
	painLevelStatistics.upvoteCount = projectUpvotes.length
	let upvoteCountFourWeeksAgo = 0
	for (const upvote of projectUpvotes) {
		if (
			isBefore(parseISO(upvote.createdAtIso), sub(new Date(), { weeks: 4 }))
		) {
			upvoteCountFourWeeksAgo++
		}
	}

	let totalFeedbackCount = 0
	let feedbackCountFourWeeksAgo = 0
	for (const feedback of projectFeedbacks) {
		const numericalPainLevel = getPainLevelNumericalValue(feedback.painLevel)
		totalFeedbackCount += numericalPainLevel

		painLevelStatistics.feedbacks[feedback.painLevel] += 1

		if (
			isBefore(parseISO(feedback.createdAtIso), sub(new Date(), { weeks: 4 }))
		) {
			feedbackCountFourWeeksAgo += numericalPainLevel
		}
	}
	const trend =
		totalFeedbackCount -
		feedbackCountFourWeeksAgo +
		(painLevelStatistics.upvoteCount - upvoteCountFourWeeksAgo) +
		issueSumTrend
	const painLevel =
		R.sumBy(projectFeedbacks, (x) => getPainLevelNumericalValue(x.painLevel)) +
		painLevelStatistics.upvoteCount +
		issueSumPain
	return {
		trend,
		painLevel,
		painLevelStatistics,
	}
}
