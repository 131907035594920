import type { StateCreator } from "zustand"
import { create } from "zustand"
import { persist } from "zustand/middleware"

export type CompaniesSortType =
	| "name-asc"
	| "name-desc"
	| "newest"
	| "oldest"
	| "notes-asc"
	| "notes-desc"

interface CompaniesSortState {
	sortType: CompaniesSortType
	setSortType: (type: CompaniesSortType) => void
}

const filterDefinition: StateCreator<CompaniesSortState> = (set) => ({
	sortType: "notes-desc",
	setSortType: (type) => {
		set({ sortType: type })
	},
})

export const useCompaniesSortState = create<CompaniesSortState>()(
	persist(filterDefinition, {
		name: "companies-sort-state",
	}),
)
