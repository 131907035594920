import { mergeAttributes, Node } from "@tiptap/core"
import HeadingExtension from "@tiptap/extension-heading"
import ImageExtension from "@tiptap/extension-image"
import ItalicExtension from "@tiptap/extension-italic"
import StarterKit from "@tiptap/starter-kit"

import type { Extensions } from "@tiptap/core"
import { DisableCommandEnter } from "./extensions"
import { CustomTaskItemExtension } from "./extensions/custom-task-item-extension"
import { CustomTaskListExtension } from "./extensions/custom-task-list-extension"
import { EmExtension } from "./extensions/em-extension"
import { EnterAsBreakExtension } from "./extensions/enter-as-break-extension"
import { HardBreakExtension } from "./extensions/hard-break-extension"
import { HighlightExtension } from "./extensions/highlight-extension"
import { LinkExtension } from "./extensions/link-extension/link-extension"
import { MessageExtension } from "./extensions/message-extension"
import { StrongExtension } from "./extensions/strong-extension"
import { TimestampExtension } from "./extensions/timestamp-extension"

export function getDefaultExtensions({
	image = true,
	heading = true,
	history = true,
	messageExtension = true,
	timestampExtension = true,
	enableCmdEnter = false,
	enterAsBreakeExtension = false,
}: {
	image?: boolean
	heading?: boolean
	history?: boolean
	messageExtension?: boolean
	timestampExtension?: boolean
	enableCmdEnter?: boolean
	enterAsBreakeExtension?: boolean
}) {
	const exts: Extensions = [
		StarterKit.configure({
			italic: false,
			...(history ? {} : { history: false }), // disable history (undo/redo)
			heading: false,
		}),
		ItalicExtension.extend({
			addKeyboardShortcuts() {
				return {
					// ↓ your new keyboard shortcut
					"Mod-i": () => {
						return this.editor.commands.toggleItalic()
					},
					"Mod-I": () => {
						return false
					},
				}
			},
		}),
		LinkExtension.extend({
			addKeyboardShortcuts() {
				return {
					Space: () => {
						if (
							this.editor &&
							this.editor.isFocused &&
							this.editor.isActive("link")
						) {
							this.editor.chain().focus().unsetMark("link").run()
						}

						return false
					},
				}
			},
		}),

		HighlightExtension.configure({ multicolor: true }),
		CustomTaskItemExtension.configure({}),
		CustomTaskListExtension.configure({}),
		EmExtension.configure({}),
		HardBreakExtension.configure({}),
		StrongExtension.configure({}),
	]
	if (!enableCmdEnter) {
		exts.push(DisableCommandEnter)
	}
	if (enterAsBreakeExtension) {
		exts.push(EnterAsBreakExtension.configure({}))
	}
	if (image) {
		exts.push(ImageExtension.configure({}))
	}
	if (heading) {
		exts.push(HeadingExtension.configure({}))
	}
	if (messageExtension) {
		exts.push(MessageExtension)
	}
	if (timestampExtension) {
		exts.push(TimestampExtension)
	}

	return exts
}

/*
export { StrongExtension } from "./extensions/strong-extension"
export { HardBreakExtension } from "./extensions/hard-break-extension"
export { EmExtension } from "./extensions/em-extension"
export { CustomTaskListExtension } from "./extensions/custom-task-list-extension"
export { CustomTaskItemExtension } from "./extensions/custom-task-item-extension"
export { HighlightExtension } from "./extensions/highlight-extension"

Ideally we should export all extensions to keep the codebase consistent and predictable.
*/

export { DisableCommandEnter } from "./extensions/disable-command-enter"

export const HeadingExtensionWithoutH1 = HeadingExtension.extend({
	// there should only be one H1 on a page, so we render H1 in the changelog as H2
	renderHTML({ HTMLAttributes, node }) {
		return [
			node.attrs.level === 1 ? "h2" : `h${node.attrs.level}`,
			HTMLAttributes,
			0,
		]
	},
})

export const VideoExtension = Node.create({
	name: "video", // unique name for the Node
	group: "block", // belongs to the 'block' group of extensions
	selectable: true, // so we can select the video
	draggable: true, // so we can drag the video
	atom: true, // is a single unit

	addAttributes() {
		return {
			src: {
				default: null,
			},
			style: {
				default: "aspect-ratio:16/9;",
			},
			controls: {
				default: true,
				renderHTML: (attributes) => {
					if (attributes.controls) {
						return { controls: "" }
					} else {
						return {}
					}
				},
			},
			autoplay: {
				default: false,
				renderHTML: (attributes) => {
					// … and return an object with HTML attributes.
					if (attributes.autoplay) {
						return { autoplay: "" }
					} else {
						return {}
					}
				},
			},
			muted: {
				default: true,
				renderHTML: (attributes) => {
					// … and return an object with HTML attributes.
					if (attributes.muted) {
						return { muted: "" }
					} else {
						return {}
					}
				},
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: "video",
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ["video", mergeAttributes(HTMLAttributes)]
	},
})

export { MessageExtension } from "./extensions/message-extension"
