import type { ReadonlyJSONValue } from "replicache"

import type { VideosGet, VideoUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

type PreviousType = VideosGet[number] | null

export async function videoUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<VideoUpdate>) {
	const key = `${workspaceId}/videos/${args.id}`

	const prev = (await tx.get(key)) as PreviousType

	if (!prev || typeof prev !== "object") {
		throw new Error("Video not found to update")
	}

	const newVideo: VideosGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newVideo as ReadonlyJSONValue)
}
