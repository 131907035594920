import type { ReadonlyJSONValue } from "replicache"

import type { UtterancesGet, UtteranceUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

type PreviousType = UtterancesGet[number] | null

export async function utteranceUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<UtteranceUpdate>) {
	const key = `${workspaceId}/utterances/${args.id}`

	const prev = (await tx.get(key)) as PreviousType

	if (!prev || typeof prev !== "object") {
		throw new Error("Utterance not found to update")
	}

	const newUtterance: UtterancesGet[number] = {
		...prev,
		...args,
	}

	return await tx.set(key, newUtterance as ReadonlyJSONValue)
}
