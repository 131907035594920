import { useSubscribe } from "replicache-react"

import type { ReplicacheMutations, WorkspacesGet } from "@productlane/api"

import { getSegments } from "../procedures"
import { useDataStoreActions } from "../store"

export function useSegmentsSubscribe({
	replicache,
	workspace,
}: {
	replicache: ReplicacheMutations | null
	workspace: WorkspacesGet[number] | undefined
}) {
	const { setSegments } = useDataStoreActions()

	useSubscribe(
		replicache,
		async (tx) => {
			if (workspace) {
				const s = await getSegments({
					tx: tx,
					workspaceId: workspace.id,
					args: {},
				})
				setSegments(s)
				return s
			}
			return []
		},
		{ default: [], dependencies: [workspace] },
	)
}
