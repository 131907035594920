import { create } from "zustand"

import type { NoteTemplate } from "../types/"

interface TemplateState {
	templates: NoteTemplate[]
	setTemplates: (templates: NoteTemplate[]) => void
	updateTemplate: (id: string, content: Partial<NoteTemplate>) => void
	getTemplate: (id: string) => NoteTemplate | undefined
}

export const useTemplateStore = create<TemplateState>((set, get) => ({
	templates: [],
	setTemplates: (templates) => set({ templates }),
	updateTemplate: (id, content) =>
		set((state) => ({
			templates: state.templates.map((template) =>
				template.id === id ? { ...template, ...content } : template,
			),
		})),
	getTemplate: (id: string) => {
		const { templates } = get()
		return templates.find((template) => template.id === id)
	},
}))
