import type { Plan } from "@productlane/lib/src/browser"

import { api } from "./api"

export function useRequiredPlans(required: Plan[]) {
	const planQuery = api.workspace.activePlan.useQuery(undefined, {
		cacheTime: Infinity,
		staleTime: Infinity,
	})

	return {
		passes: planQuery.data ? required.includes(planQuery.data) : false,
		initialized: !!planQuery.data,
	}
}
