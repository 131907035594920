import { parseISO } from "date-fns"
import * as R from "remeda"

import type { ContactsGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function contactsGet({ tx, workspaceId }: QueryContext<object>) {
	const values = await tx
		.scan({ prefix: `${workspaceId}/contacts/` })
		.values()
		.toArray()
	const typed = values as unknown as ContactsGet
	return R.sortBy(typed, [(x) => parseISO(x.createdAtIso), "desc"])
}
