import type { ChangelogDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function changelogDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<ChangelogDelete>) {
	return tx.del(`${workspaceId}/changelogs/${args.id}`)
}
