import type { VideoDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function videoDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<VideoDelete>) {
	return tx.del(`${workspaceId}/videos/${args.id}`)
}
