import type { ReadonlyJSONValue } from "replicache"

import type { IssuesGet, IssueUnlink, ThreadsGet } from "@productlane/api"

import type { MutationContext } from "../../types"
import { threadGet } from "../threads/getId"

export async function issueUnlink({
	tx,
	workspaceId,
	args,
}: MutationContext<IssueUnlink>) {
	const key = `${workspaceId}/issues/${args.internalIssueId}`
	const issue = (await tx.get(key)) as unknown as IssuesGet[number] | undefined
	if (!issue) {
		throw new Error("Issue not found")
	}
	const newIssue: IssuesGet[number] = {
		...issue,
		feedbackIds: issue.feedbackIds.filter((f) => f !== args.feedbackId),
	}
	await tx.set(key, newIssue as unknown as ReadonlyJSONValue)

	const feedback = await threadGet({
		workspaceId,
		tx,
		args: { id: args.feedbackId },
	})
	if (!feedback) {
		throw new Error("Could not find feedback")
	}
	const newFeedback: ThreadsGet[number] = {
		...feedback,
		linkedIssueIds: feedback.linkedIssueIds.filter(
			(i) => i !== args.internalIssueId,
		),
	}
	await tx.set(
		`${workspaceId}/threads/${feedback.id}`,
		newFeedback as unknown as ReadonlyJSONValue,
	)
}
