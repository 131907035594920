import { useSubscribe } from "replicache-react"

import type { ReplicacheMutations, WorkspacesGet } from "@productlane/api"

import { sidebarSectionsGet } from "../procedures"
import { useDataStoreActions } from "../store"

export function useSidebarSectionsSubscribe({
	replicache,
	workspace,
}: {
	replicache: ReplicacheMutations | null
	workspace: WorkspacesGet[number] | undefined
}) {
	const { setSidebarSections } = useDataStoreActions()

	useSubscribe(
		replicache,
		async (tx) => {
			if (workspace) {
				const c = await sidebarSectionsGet({
					tx,
					workspaceId: workspace.id,
					args: {},
				})
				setSidebarSections(c)
				return c
			}
			return []
		},
		{ default: [], dependencies: [workspace] },
	)
}
