import { useMemo } from "react"
import { parseISO, startOfMonth } from "date-fns"

import {
	FREE_PLAN_LINK_LIMIT,
	RecordingLimits,
} from "@productlane/lib/src/plans-client"

import { useThreads, useVideos, useWorkspace } from "./replicache"

export function useNoteLinkAmount() {
	const workspace = useWorkspace()
	const threads = useThreads()
	const noteLinkAmount = useMemo(() => {
		let count = 0
		threads.forEach((thread) => {
			count =
				count +
				thread.tagIds.length +
				thread.linkedIssueIds.length +
				thread.linkedProjectIds.length
		})
		return count
	}, [threads])

	return {
		noteLinkAmount,
		noteLinkAmountExceeded:
			workspace?.plan === "free" && noteLinkAmount >= FREE_PLAN_LINK_LIMIT,
	}
}

export function useRecordingAmount() {
	const threads = useThreads()
	const videos = useVideos()
	const workspace = useWorkspace()
	const recordingsInMonth = useMemo(() => {
		// amount of videos since the start of the month
		// When a note is deleted the video isn't deleted at the moment
		// That's why we need to check whether a note exists for a video too
		const now = new Date()
		const start = startOfMonth(now)
		return videos.filter((video) => {
			const videoDate = parseISO(video.createdAtIso)
			const thread = threads.find((thread) => thread.videoId === video.id)
			return videoDate >= start && !!thread
		}).length
	}, [threads, videos])
	return {
		recordingCount: recordingsInMonth,
		isRecordingCountExceeded:
			recordingsInMonth >= RecordingLimits[workspace?.plan ?? "free"],
	}
}
