/* eslint-disable @typescript-eslint/no-explicit-any */
import { Mark, markInputRule, mergeAttributes } from "@tiptap/core"

export interface ItalicOptions {
	HTMLAttributes: Record<string, any>
}

// Linear uses EM instead of Italic

export const starInputRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))$/
export const starPasteRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))/g
export const underscoreInputRegex = /(?:^|\s)((?:_)((?:[^_]+))(?:_))$/
export const underscorePasteRegex = /(?:^|\s)((?:_)((?:[^_]+))(?:_))/g

export const EmExtension = Mark.create<ItalicOptions>({
	name: "em",

	addOptions() {
		return {
			HTMLAttributes: {},
		}
	},

	parseHTML() {
		return [
			{
				tag: "em",
			},
			{
				tag: "i",
				getAttrs: (node) => node.style.fontStyle !== "normal" && null,
			},
			{
				style: "font-style=italic",
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return [
			"em",
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
			0,
		]
	},

	addInputRules() {
		return [
			markInputRule({
				find: starInputRegex,
				type: this.type,
			}),
			markInputRule({
				find: underscoreInputRegex,
				type: this.type,
			}),
		]
	},
})
