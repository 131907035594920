import type { EmailUndo } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function emailUndo({
	tx,
	workspaceId,
	args,
}: MutationContext<EmailUndo>) {
	const key = `${workspaceId}/emails/${args.id}`
	return tx.del(key)
}
